import React, { useState } from 'react'
import { useLocation } from 'react-router';
import { DefaultFooter, PreSignInHeader } from './common/HeaderAndFooter'
import API from '../api';


export default function DeleteAccountConfirmationPage() {
  const { state } = useLocation();

  const [isDeleting, setIsDeleting] =  useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  
  function handleAccountDeletion() {
    setIsDeleting(true);
    API.deleteAccount(state.token).then(response => {
      if (response.ok) {
        setIsDeleted(true);
      } else {
        setIsDeleting(false);
      }
    });
  }

  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container relative rounded-t-3xl bg-brand-primary-regular py-16 md:py-20">
          {isDeleted 
            ? <div className="max-w-xl mx-auto space-y-4">
                <p className="text-content-regular text-2xl">Your Allay account associated with {state.email} has been successfully deleted.</p>
              </div>
            : <div className="max-w-xl mx-auto space-y-4">
                <p className="text-content-regular text-2xl">Are you sure you want to delete your Allay account associated with {state.email}?</p>
                <div className='bg-brand-primary-light p-4 rounded-xl'>
                  <p className='text-content-error'><span className='font-semibold underline'>Important Warning:</span> Permanent Data Loss Upon Account Deletion</p>
                </div>
                <p className='pt-8'>Please be aware that if you choose to proceed with deleting your account, you will permanently lose all data associated with your account. This includes:</p>
                <p className='pl-4'><span className='font-semibold'>All App Data:</span> Any progress, preferences, and personal information stored within the app will be irretrievably deleted.</p>
                <p className='pl-4'><span className='font-semibold'>Payment Data:</span> Your payment history and related details will be permanently removed and cannot be recovered.</p>
                <p>This action is irreversible. Once you delete your account, there is no way to retrieve any of the lost data.</p>
                <p className='pb-8'>Please consider this carefully before deciding to delete your account. If you have any doubts or questions, we recommend consulting our support team for assistance before making a final decision.</p>
                <button className='btn-negative' disabled={isDeleting} onClick={handleAccountDeletion}>Proceed with account deletion</button>
              </div>
          }
        </div>
      </div>
      <DefaultFooter />
    </div>
  )
}
