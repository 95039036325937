import React from 'react'
import { DefaultFooter, PreSignInHeader } from './common/HeaderAndFooter'

export const COMPANY_NAME = "Griffin Labs";
export const COMPANY_ADDRESS_STREET = "6633 Flagstone Pl,";
export const COMPANY_ADDRESS_REGION = "Rancho Cucamonga, CA 91739";
export const COMPANY_EMAIL = "hello@getallay.com"

export default function ResearchOITPage() {

  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container relative rounded-t-3xl bg-brand-primary-regular py-16 md:py-20">
          <h1 className="text-4xl max-w-2xl mx-auto text-center pb-8">Learn What Oral Immunotherapy is and What They Do</h1>
          <div className="max-w-lg mx-auto">
            <div className="space-y-8">
              <p className="text-lg">Having a peanut allergy can be tough to deal with. People with this condition, as well as their loved ones, have to be very careful when reading food labels and always worry about accidentally coming into contact with peanuts. This constant concern can have a significant impact on their daily lives, even making simple activities like eating out or going to a party stressful experiences. Fortunately, there is a new area of research called oral immunotherapy (OIT) that offers hope for those with peanut allergies. In this article, we'll examine OIT in detail, looking at its potential, safety, and impact on the lives of those affected by this condition.</p>
              <p className="text-2xl font-medium">A Hope for Tolerance: The Power of OIT</p>
              <p className="text-lg">For individuals living with peanut allergies, tolerance is the ultimate goal, a state where the body becomes unfazed by peanuts. Picture a world where indulging in a peanut butter sandwich or a peanut-topped sundae would be unaccompanied by the fear of a reaction. The pursuit of this possibility is what drives the research into OIT.</p>
              <p className="text-lg">OIT works by gradually acclimating the body to peanuts, by providing incrementally increased doses of peanut proteins. Some studies report that OIT can assist patients in reaching “sustained unresponsiveness,”where the body retains the ability to ingest peanuts without adverse reactions, even after a period of abstinence. However, the achievement of true tolerance—wherein the body entirely ceases to react adversely to peanuts—is still elusive.</p>
              <p className="text-lg">The “maintenance dose”, the amount of peanut protein consumed during OIT's extended phase, is believed to significantly influence this tolerance development process. For instance, some research suggests that lower doses (around 300mg) might not be sufficient to induce true tolerance. On the other hand, a more substantial dose (2000-4000mg) may potentially yield more effective results. This suggests that a “one size fits all” approach may not be the best course of action with OIT. Instead, patient-specific dosing, much like a personalized roadmap, could be the key to achieving a successful outcome.</p>
              <p className="text-2xl font-medium">Gauging Safety: Understanding the Challenges and Solutions in OIT</p>
              <p className="text-lg">Despite its promising aspects, OIT is not without potential hazards. Like many treatments, patients undergoing OIT are susceptible to experiencing side effects, some of which can be severe allergic reactions. Several studies have pointed out that although these reactions decrease with time, they do not completely disappear, even after two years of therapy.</p>
              <p className="text-lg">However, the safety landscape of OIT is not all bleak. The inherent risks associated with OIT can be mitigated through careful patient selection, meticulous monitoring, and personalized dosing strategies. Just like an experienced traveler adapts their journey based on the weather forecast and the road conditions, the journey through OIT can be optimized by considering these critical safety aspects. Furthermore, exciting developments, such as the use of complementary therapies like anti-IgE monoclonal antibodies, are showing promising potential in enhancing OIT's safety by reducing the severity of allergic reactions.</p>
              <p className="text-2xl font-medium">The Quality of Life Quotient: Assessing the Impact of OIT</p>
              <p className="text-lg">When you're living with a peanut allergy, it can feel like being perpetually on guard. Consequently, it's crucial to explore how OIT can affect the quality of life (QoL) for patients and their families. While the available data are somewhat mixed, several studies point to the potential of OIT to positively impact QoL, albeit cautiously due to potential biases.</p>
              <p className="text-lg">Interestingly, research reveals that the benefits of OIT on QoL might be more noticeable in caregivers than the patients themselves. This may be due to the lifting of the constant burden of worry from the shoulders of caregivers, who have had to remain constantly vigilant to protect their children from accidental exposure.</p>
              <p className="text-lg">In contrast, children undergoing OIT reported less dramatic improvements in QoL. A study by Reier-Neilsen et al. found that children reported no significant improvement after two years of OIT compared to a control group, whereas parents noted a two-fold change in their children's QoL. Similarly, another study by Blumchen et al. indicated that 82% of children and their mothers in the OIT group reported positive experiences about their treatment.</p>
              <p className="text-lg">These findings emphasize the complexity and variance of the OIT journey, underscoring the need for careful interpretation and more robust, specially-designed tools to measure QoL in the context of OIT.</p>
              <p className="text-2xl font-medium">Future Prospects: The Road Ahead for OIT</p>
              <p className="text-lg">Peanut OIT has emerged as a promising avenue in allergen research. However, there are still uncharted territories to explore, from understanding the potential to induce true tolerance, refining the safety measures, to measuring its real-life impact on QoL more accurately.</p>
              <p className="text-lg">Living with peanut allergies doesn't just require steering clear of peanuts; it demands continual vigilance, a significant alteration in diet, and a distinct change in lifestyle. Peanut OIT research aspires to alter this narrative, offering the potential to navigate through life with fewer constraints and a greater sense of freedom.</p>
              <p className="text-lg">In this evolving field, every new study brings fresh insights into improving the safety, efficacy, and overall quality of life for those with peanut allergies. With each stride forward, we hope to make the world a less daunting place for individuals living with peanut allergies, transforming the dream of a carefree indulgence in a peanut-filled treat into a reality. The ultimate goal is to usher in a future where the word “peanut' evokes less fear and more enjoyment.</p>

              <p className="pt-4 text-sm">Patrawala M, Shih J, Lee G, Vickery B. Peanut Oral Immunotherapy: a Current Perspective. Curr Allergy Asthma Rep. 2020 Apr 20;20(5):14. doi: 10.1007/s11882-020-00908-6. PMID: 32314071; PMCID: PMC7223701.</p>
            </div>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div >
  )
}