export default class API {
  static getApiEndpoint() {
    return window.location.hostname.includes("getallay.com") ? 'https://api.getallay.com' : (process.env.NODE_ENV === 'production') ? 'https://getallay.pythonanywhere.com' : 'http://127.0.0.1:8000';
  }

  static async getProductLists() {
    const url = `${API.getApiEndpoint()}/product/get`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  static async calculateSaleTax(calculateSaleTaxRequestParams) {
    const url = `${API.getApiEndpoint()}/product/sale-tax`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(calculateSaleTaxRequestParams)
    });
  }

  static async placeAnOrder(orderRequestParams) {
    const url = `${API.getApiEndpoint()}/transaction/new-customer-purchase`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(orderRequestParams)
    });
  }

  static async sendEmailWithSignInPassword(emailParam) {
    const url = `${API.getApiEndpoint()}/auth/send-email-with-sign-in-password`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(emailParam)
    });
  }

  static async signIn(signInParam) {
    const url = `${API.getApiEndpoint()}/auth/sign-in`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(signInParam)
    });
  }

  static async signOut(refreshToken) {
    const url = `${API.getApiEndpoint()}/auth/sign-out`
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({'refresh': refreshToken})
    });
  }

  static async deleteAccount(token) {
    const url = `${API.getApiEndpoint()}/auth/delete-allay-account`
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
  }

  static async getUserInfo(token) {
    const url = `${API.getApiEndpoint()}/auth/get-user-info`
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  static async updateUserShippingAddress(token, updateParams) {
    const url = `${API.getApiEndpoint()}/subscription/shipping/update-address`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(updateParams)
    });
  }

  static async updateUserBillingInfo(token, updateParams) {
    const url = `${API.getApiEndpoint()}/subscription/billing/update-payment-info`;
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(updateParams)
    });
  }

  static async getUserSubscriptionInfo(token) {
    const url = `${API.getApiEndpoint()}/subscription/get-info`
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  static async getTransactionDetail(token, transId) {
    const url = `${API.getApiEndpoint()}/subscription/get-transaction-detail?transId=${transId}`
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  static async unsubcribe(token) {
    const url = `${API.getApiEndpoint()}/subscription/unsubscribe`
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  static async submitPrivacyOptOut(optOutParams) {
    const url = `${API.getApiEndpoint()}/auth/privacy/opt-out`
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(optOutParams)
    });
  }
}

export function GetAPIErrorMessage(err) {
  if (err.constructor === Object) {
    return GetAPIErrorMessage(Object.values(err)[0]);
  } else if (err instanceof Array) {
    return GetAPIErrorMessage(err[0]);
  }

  return err;
}