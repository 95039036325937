import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from "./common/InputField";
import { PreSignInHeader, DefaultFooter } from './common/HeaderAndFooter';
import API, { GetAPIErrorMessage } from "../api";
import { DELETE_ACCOUNT_CONFIRMATION_URL } from './common/Navigation';


export default function DeleteAccountLoginPage() {
  let navigate = useNavigate();

  const EMAIL_ERROR_MSG = "Please enter a valid email address";
  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState('');

  const SEND_EMAIL_FAIL_MSG = "Oops, looks like something went wrong. Please try again.";
  const [sendEmailError, setSendEmailError] = useState('');
  const [disableSendOTPEmailButton, setDisableSendOTPEmailButton] = useState(false);

  const [hideOTPField, setHideOTPField] = useState(true);
  const [otp, setOtp] = useState('');
  const [signInError, setSignInError] = useState('');
  const [disableSignInButton, setDisableSignInButton] = useState(false);

  function handleRetrieveOneTimePassCode() {
    // Check if the email address has the correct format
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(email)) {
      setEmailError("");
      setSendEmailError("");
      setDisableSendOTPEmailButton(true);
      API.sendEmailWithSignInPassword({
        "email": email
      }).then(response => {
        if (response.ok) {
          setDisableSendOTPEmailButton(false);
          setDisableSignInButton(false);
          setHideOTPField(false);
        } else {
          throw response;
        }
      }).catch(e => {
        setSendEmailError(SEND_EMAIL_FAIL_MSG);
        setDisableSendOTPEmailButton(false);
        console.log(e);
      });
    } else {
      setEmailError(EMAIL_ERROR_MSG);
    }
  }

  function handleSignIn() {
    setDisableSignInButton(true);
    API.signIn({
      "email": email,
      "password": otp
    }).then(response => {
      if (response.ok) {
        response.json().then(user_info => {
          navigate(DELETE_ACCOUNT_CONFIRMATION_URL, { state: { token: user_info.data["tokens"]["access"], email: email } });
        });
      } else {
        throw response;
      }
    }).catch(e => {
      e.json().then(err => setSignInError(GetAPIErrorMessage(err)));
      setDisableSignInButton(false);
      console.log(e);
    });
  }

  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container rounded-t-3xl bg-brand-primary-regular py-36">
          <div className="max-w-md mx-auto text-center">
            <h1 className="text-2xl sm:text-3xl text-content-regular pb-2">Sign in to delete your account</h1>
            {hideOTPField ?
              <div className="space-y-6 pt-12">
                <div className="space-y-2">
                  {emailError.length > 0 && <p className="text-content-error text-left">{emailError}</p>}
                  <InputField
                    label="Email address"
                    name="email"
                    type="email"
                    autocomplete="email"
                    x_autocompletetype="email"
                    attention={emailError.length > 0}
                    onChange={(event) => setEmail(event.target.value)} />
                </div>
                <div className="space-y-2">
                  {sendEmailError.length > 0 && <p className="text-content-error text-left">{sendEmailError}</p>}
                  <button className="btn-outline w-full" disabled={disableSendOTPEmailButton} onClick={handleRetrieveOneTimePassCode}>Get one-time password</button>
                </div>
              </div>
              : <div className="space-y-4 pt-12">
                <p className="max-w-[55ch] text-left">An email with one-time login password has been sent to <span className="font-semibold">{email}</span>.</p>
                <InputField
                  label="Enter your password"
                  name="password"
                  type="text"
                  onChange={(event) => setOtp(event.target.value)} />
                <div className="space-y-4 pt-8">
                  <div className="space-y-2">
                    {signInError.length > 0 && <p className="text-content-error text-left">{signInError}</p>}
                    <button className="btn-primary w-full" onClick={handleSignIn} disabled={disableSignInButton}>Sign In</button>
                  </div>
                  <p className="text-left text-content-light text-sm"><span className="font-semibold text-content-regular">Didn't received the email?</span> Please check your promotion/spam folder or <span className="text-content-regular underline cursor-pointer" onClick={() => setHideOTPField(true)}>re-enter the correct email</span> you've registered with us.</p>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  )
}

