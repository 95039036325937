import { useNavigate } from "react-router-dom";
import { DefaultFooter, PreSignInHeader } from './common/HeaderAndFooter';
import { AiFillCheckCircle } from "react-icons/ai";
import { FOUNDER_FACEBOOK, FOUNDER_LINKEDIN, MILESTONES_URL, OVERVIEW_URL, PRODUCT_URL } from "./common/Navigation";
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa';

export default function HomePage() {
  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <HomePageHeadingSection />
        <HomePageIntroSection />
        <HomePageWhatIsAllaySection />
        <HomePageImageGallerySection />
        <HomePageWhyAllaySection />
      </div>
      <DefaultFooter />
    </div>
  )
}

function HomePageHeadingSection() {
  return (
    <div className="h-[29rem] sm:h-[34rem] relative flex flex-col justify-center">
      <div className="section-container relative overflow-hidden w-full rounded-t-3xl bg-brand-primary-regular mx-auto h-[24rem] sm:h-[30rem]">
        {/* <Logo className="flex justify-center text-content-regular" /> */}

        <img className="absolute h-[44rem] -bottom-[22rem] sm:-bottom-[28rem] object-contain left-1/2 transform -translate-x-1/2" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1705620979/allay_banner_wtynzi.png" alt="Allay banner"/>
      </div>
      <div className="section-container w-full h-[5rem] sm:h-[4rem] bg-white"></div>
      <img className="absolute object-contain h-[22rem] sm:h-[24rem] bottom-0 left-1/2 transform -translate-x-1/2" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1705621349/allay_product_usa_430_rq3uj2.png" alt="Allay product bottle" />
    </div>
  )
}

function HomePageIntroSection() {
  return (
    <div className="section-container relative bg-white sm:text-center pt-16">
      <h1 className="text-content-regular max-w-[32ch] mx-0 sm:mx-auto text-3xl lg:text-4xl">
        Hi, I'm Minh-Thao. I develop Allay to help my daughter live her life free from the fear of peanut allergy
      </h1>
      <p className="py-2 text-content-regular max-w-[52ch] mx-auto text-lg sm:text-xl">As a parent to a peanut-allergic toddler, I intimately grasp the anxiety and uncertainty that accompanies every meal. It was my own journey that spurred the creation of Allay—a product stemming from a father's heartfelt drive to safeguard and enhance his daughter's well-being.</p>
      <div className="pt-4">
        <img className="w-[100px] h-[100px] sm:mx-auto mb-4" src="https://res.cloudinary.com/dqafyoayf/image/upload/w_400,h_400,c_fill,g_face,r_max/v1705637967/allay_founder_portrait.jpg" alt="Allay founder"/>
        <p>Minh-Thao Nguyen</p>
        <p className="text-content-regular flex flex-row -ml-2 sm:text-center sm:justify-center">
          <span className="py-2 px-2 cursor-pointer" onClick={() => window.open(FOUNDER_LINKEDIN, '_blank')}><FaLinkedin size="20" /></span>
          <span className="py-2 px-2 cursor-pointer border-l border-content-lighter" onClick={() => window.open(FOUNDER_FACEBOOK, '_blank')}><FaFacebookSquare size="20" /></span>
        </p>
        <p className="text-content-light">Co-Founder of Allay, Google Engineer, Stanford Alum</p>
      </div>
      <div className="pb-4">
        <button className="btn-outline" onClick={() => window.open("https://calendly.com/getallay/get-to-know-allay", "_blank")}>Get in touch</button>
      </div>
      <div className="h-40 w-px bg-content-regular hidden mx-auto sm:block"></div>
    </div>
  )
}

function HomePageImageGallerySection() {
  return (
    <div className="section-container bg-white sm:text-center sm:pt-4">
      <img className="max-w-[800px] w-full object-contain mx-auto" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1705656013/allay_gallery.jpg" alt="Allay product gallery"/>
    </div>
  )
}

function HomePageWhatIsAllaySection() {
  let navigate = useNavigate();

  return (
    <div className="section-container relative bg-white sm:text-center sm:pt-4 pb-16">
      <h1 className="text-content-regular max-w-[32ch] mx-auto text-3xl lg:text-4xl">
        What is Allay?
      </h1>
      <p className="pt-2 pb-6 text-content-regular max-w-[46ch] mx-auto text-lg sm:text-xl">Crafted from safe, organic ingredients, Allay is developed on the foundation of peanut oral immunotherapy.</p>
      <div className="space-y-6 max-w-xl mx-auto pb-8">
        <div className="space-y-2">
          <p className="flex text-left sm:text-lg font-semibold"><span className="text-brand-secondary-regular mt-1 w-4 mr-2"><AiFillCheckCircle size="18" /></span>Safe, controlled amounts of peanuts</p>
          <p className="text-left px-6 sm:text-lg">Each Allay capsule are crafted with a safe and controlled amount of peanuts, allowing parents to provide their toddler a tailored, gentle approach to building tolerance.</p>
        </div>
        <div className="space-y-2">
          <p className="flex text-left sm:text-lg font-semibold"><span className="text-brand-secondary-regular mt-1 w-4 mr-2"><AiFillCheckCircle size="18" /></span>A comprehensive, solid plan to advance the progression</p>
          <p className="text-left px-6 sm:text-lg">Allay’s free companion app enables parents to track their toddler's progress, set daily intake reminders, and get personalized dosage increase suggestions.</p>
        </div>
        <div className="space-y-2">
          <p className="flex text-left sm:text-lg font-semibold"><span className="text-brand-secondary-regular mt-1 w-4 mr-2"><AiFillCheckCircle size="18" /></span>Made from organic, safe ingredients</p>
          <p className="text-left px-6 sm:text-lg">Allay is crafted with the finest organic ingredients, prioritizing the well-being of our little ones. Proudly manufactured and packaged in California, USA, our product ensures quality and care in every step.</p>
        </div>
      </div>
      <button className="btn-outline" onClick={() => navigate(MILESTONES_URL)}>View Allay journey</button>
    </div>
  )
}

function HomePageWhyAllaySection() {
  let navigate = useNavigate();

  return (
    <div className="section-container relative bg-white sm:text-center pt-12 pb-32">
      <h1 className="text-content-regular max-w-[32ch] mx-auto text-3xl lg:text-4xl">
        Why Allay?
      </h1>
      <div className="space-y-6 max-w-xl mx-auto pb-8">
        <p className="text-lg sm:text-xl">Witnessing my daughter's gradual decrease in peanut sensitivity has given us hope that she can explore her world with less fear.</p>
        <p>Knowing the difference it made for us, I felt compelled to share Allay with other families. Every child deserves a chance at a less restricted life, and every parent deserves peace of mind.</p>
        <p>Join me in empowering our children against peanut allergies. With Allay, let's turn our shared challenges into a beacon of hope.</p>
      </div>
      <div className="space-x-4">
        <button className="btn-primary" onClick={() => navigate(PRODUCT_URL)}>Get Allay</button>
        <button className="btn-outline" onClick={() => navigate(OVERVIEW_URL)}> Learn more</button>
      </div>
    </div>
  )
}