import React from 'react'
import { PreSignInHeader, DefaultFooter } from './common/HeaderAndFooter';

export default function NotFoundPage() {
  return (
    <div className="bg-brand-tertiary-darker h-screen">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container flex items-center rounded-t-3xl bg-brand-primary-regular h-[50vh] py-16 md:py-20">
          <div className="max-w-xl mx-auto space-y-4 text-center">
            <h1 className="text-4xl md:text-5xl text-content-regular pb-4">404 not found</h1>
            <p className="text-xl">The link your are trying to navigate to is not available or is no longer exist. Please check your url for any possible typos.</p>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  )
}