import React from 'react'
import * as rdd from 'react-device-detect';
import { PreSignInHeader, DefaultFooter } from './common/HeaderAndFooter';
import { APPLE_STORE_APP_LINK, GOOGLE_PLAY_STORE_LINK } from './common/Navigation';

export default function DownloadAppPage() {
  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container flex items-center rounded-t-3xl bg-brand-primary-regular py-16 md:py-20 lg:pt-0">
          <div className="flex justify-between space-x-0 lg:space-x-20 -space-y-16 md:space-y-0 flex-col lg:flex-row relative">
            <div className="flex flex-col justify-center items-center lg:items-start space-y-4 w-full text-center lg:w-8/12 lg:text-left max-w-screen-lg z-10">
              <h1 className="text-content-regular max-w-[23ch] text-2xl md:text-3xl lg:text-4xl">Get Allay mobile app</h1>
              <p className="font-sans text-content-primaryOnDark max-w-prose">Effortlessly monitor your child's peanut tolerance journey with Allay companion application.</p>

              <div className="pt-10 pb-10 lg:pb-0">
                {rdd.isIOS
                  ? <a href={APPLE_STORE_APP_LINK} target="_blank" rel="noreferrer"><img width={200} src="https://res.cloudinary.com/dqafyoayf/image/upload/v1704667597/Apple_gq3yrt.svg" alt="Allay ios app link" /></a>
                  : rdd.isAndroid
                    ? <a href={GOOGLE_PLAY_STORE_LINK} target="_blank" rel="noreferrer"><img width={200} src="https://res.cloudinary.com/dqafyoayf/image/upload/v1704667597/Google_slzww4.svg" alt="Allay android app link" /></a>
                    : <div className="flex flex-row flex-wrap space-x-4 md:space-x-8">
                        <a href={APPLE_STORE_APP_LINK} target="_blank" rel="noreferrer"><img width={200} src="https://res.cloudinary.com/dqafyoayf/image/upload/v1704667597/Apple_gq3yrt.svg" alt="Allay ios app link" /></a>
                        <a href={GOOGLE_PLAY_STORE_LINK} target="_blank" rel="noreferrer"><img width={200} src="https://res.cloudinary.com/dqafyoayf/image/upload/v1704667597/Google_slzww4.svg" alt="Allay android app link" /></a>
                      </div>
                }
              </div>

              <div className="block lg:hidden">
                <img src="https://res.cloudinary.com/dqafyoayf/image/upload/v1704668273/download-mobile_rajrjn.svg" alt="allay app dashboard screenshot" />
              </div>
            </div>
            <div className="hidden w-4/12 lg:block">
              <div className="relative transform translate-y-24 text-center">
                <img className="w-full" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1704668273/download-web_dafjc9.svg" alt="allay app dashboard screenshot" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  )
}