export function Logo(props) {
  return (
    <div {...props}>
      <svg width="90" height="44" viewBox="0 0 90 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9 13.16H22.54V33.16H16.74L16.42 30.6C14.82 32.68 12.6467 33.72 9.9 33.72C6.96667 33.72 4.66 32.72 2.98 30.72C1.32667 28.6933 0.5 26.0667 0.5 22.84C0.5 19.6667 1.36667 17.16 3.1 15.32C4.83333 13.4533 7.11333 12.52 9.94 12.52C11.3533 12.52 12.6733 12.8133 13.9 13.4C15.1267 13.96 16.02 14.6933 16.58 15.6L16.9 13.16ZM14.98 26.8C15.8867 25.8667 16.34 24.6533 16.34 23.16C16.34 21.6667 15.8867 20.4667 14.98 19.56C14.0733 18.6267 12.9 18.16 11.46 18.16C10.02 18.16 8.86 18.6267 7.98 19.56C7.12667 20.4667 6.7 21.6667 6.7 23.16C6.7 24.6533 7.12667 25.8667 7.98 26.8C8.86 27.7067 10.02 28.16 11.46 28.16C12.9 28.16 14.0733 27.7067 14.98 26.8Z" fill="currentColor"/>
        <path d="M32.1259 33.16H25.9659V5H32.1259V33.16Z" fill="currentColor"/>
        <path d="M41.7072 33.16H35.5472V5H41.7072V33.16Z" fill="currentColor"/>
        <path d="M60.4484 13.16H66.0884V33.16H60.2884L59.9684 30.6C58.3684 32.68 56.1951 33.72 53.4484 33.72C50.5151 33.72 48.2084 32.72 46.5284 30.72C44.8751 28.6933 44.0484 26.0667 44.0484 22.84C44.0484 19.6667 44.9151 17.16 46.6484 15.32C48.3818 13.4533 50.6618 12.52 53.4884 12.52C54.9018 12.52 56.2218 12.8133 57.4484 13.4C58.6751 13.96 59.5684 14.6933 60.1284 15.6L60.4484 13.16ZM58.5284 26.8C59.4351 25.8667 59.8884 24.6533 59.8884 23.16C59.8884 21.6667 59.4351 20.4667 58.5284 19.56C57.6218 18.6267 56.4484 18.16 55.0084 18.16C53.5684 18.16 52.4084 18.6267 51.5284 19.56C50.6751 20.4667 50.2484 21.6667 50.2484 23.16C50.2484 24.6533 50.6751 25.8667 51.5284 26.8C52.4084 27.7067 53.5684 28.16 55.0084 28.16C56.4484 28.16 57.6218 27.7067 58.5284 26.8Z" fill="currentColor"/>
        <path d="M67.9144 42.76V37.6H71.3144C72.3544 37.6 73.141 37.4 73.6744 37C74.2077 36.6 74.6744 35.8667 75.0744 34.8L75.4344 33.84L67.3944 13.16H73.9944L78.3544 26L83.1944 13.16H89.5944L79.4344 37.52C78.6077 39.5467 77.5944 41.0133 76.3944 41.92C75.221 42.8533 73.7544 43.32 71.9944 43.32C70.5277 43.32 69.1677 43.1333 67.9144 42.76Z" fill="currentColor"/>
      </svg>
    </div>
  )
}