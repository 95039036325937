import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import currencyFormatter from 'currency-formatter';
import ImageGallery from "react-image-gallery";
import { DefaultFooter, PreSignInHeader } from './common/HeaderAndFooter'
import { CHECKOUT_URL, FAQ_URL, MILESTONES_URL } from './common/Navigation';
import { ExpandableFAQItem } from './FaqPage';
import API from '../api';


const PRODUCT_PHOTOS = [
  {
    original: "https://res.cloudinary.com/dqafyoayf/image/upload/w_1024,ar_1:1,c_fill,g_auto/v1698097387/allay/product_1.jpg",
    fullscreen: "https://res.cloudinary.com/dqafyoayf/image/upload/w_2048,ar_1:1,c_fill,g_auto/v1698097387/allay/product_1.jpg",
    originalAlt: "Product and packaging",
    originalClass: "rounded-2xl overflow-hidden",
  },
  {
    original: "https://res.cloudinary.com/dqafyoayf/image/upload/w_1024,ar_1:1,c_fill,g_auto/v1698088035/allay/product_2.jpg",
    fullscreen: "https://res.cloudinary.com/dqafyoayf/image/upload/w_2048,ar_1:1,c_fill,g_auto/v1698088035/allay/product_2.jpg",
    originalAlt: "Product dimension",
    originalClass: "rounded-2xl overflow-hidden",
  },
  {
    original: "https://res.cloudinary.com/dqafyoayf/image/upload/w_1024,ar_1:1,c_fill/v1698088396/allay/product_3.jpg",
    fullscreen: "https://res.cloudinary.com/dqafyoayf/image/upload/w_2048,ar_1:1,c_fill,g_auto/v1698088035/allay/product_3.jpg",
    originalAlt: "Product with mobile app",
    originalClass: "rounded-2xl overflow-hidden",
  },
  {
    original: "https://res.cloudinary.com/dqafyoayf/image/upload/w_1024,ar_1:1,c_fill/v1698088396/allay/product_4.jpg",
    fullscreen: "https://res.cloudinary.com/dqafyoayf/image/upload/w_2048,ar_1:1,c_fill,g_auto/v1698088035/allay/product_4.jpg",
    originalAlt: "Product consumption",
    originalClass: "rounded-2xl overflow-hidden",
  },
  {
    original: "https://res.cloudinary.com/dqafyoayf/image/upload/w_1024,ar_1:1,c_pad,b_auto/v1698088396/allay/product_5.jpg",
    fullscreen: "https://res.cloudinary.com/dqafyoayf/image/upload/w_2048,c_fill,g_auto/v1698088035/allay/product_5.jpg",
    originalAlt: "Supplement fact and suggested use",
    originalClass: "rounded-2xl overflow-hidden",
  }
]


export default function ProductPage() {
  let navigate = useNavigate();

  const productPhotosRef = useRef(null);
  const [program, setProgram] = useState("ADAPT");
  const [products, setProducts] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  // const [selectedProductPhotos, setSelectedProductPhotos] = useState([]);
  const [showProductImageInFullScreen, setShowProductImageInFullScreen] = useState(false);

  useEffect(() => {
    API.getProductLists().then(response => {
      if (response.ok) {
        response.json().then(info => {
          setProducts(info.data);
          setSelectedProduct(info.data[0]);
          // setSelectedProductPhotos(getProductPhotos(info.data[0]));
        });
      }
    });
  }, []);

  // function getProductPhotos(product) {
  //   let productPhotos = [];
  //   for (let photo of product.photos) {
  //     productPhotos.push({
  //       original: photo.original_photo,
  //       fullscreen: photo.full_screen_photo,
  //       originalAlt: photo.alt_text,
  //       originalClass: "rounded-2xl overflow-hidden"
  //     })
  //   }
  //   return productPhotos
  // }

  function setProgramPhase(phase) {
    setProgram(phase);
    for (let product of products) {
      if (product.phase === phase) {
        setSelectedProduct(product);
        break;
      }
    }
  }

  return (
    <div className="bg-brand-tertiary-darker">
      {!showProductImageInFullScreen && <PreSignInHeader />}
      {selectedProduct &&
        <div className="app-container overflow-hidden lg:overflow-visible">
          <div className="section-container relative rounded-t-3xl bg-brand-primary-regular py-4 sm:py-8 md:py-14 overflow-hidden lg:overflow-visible">
            <div className="grid grid-cols-2 md:gap-8 xl:gap-16 lg:pb-16">
              <div className="col-span-2 pb-4 lg:sticky lg:self-start lg:top-24 lg:m-0 lg:col-span-1">
                <ImageGallery
                  ref={productPhotosRef}
                  items={PRODUCT_PHOTOS}
                  showPlayButton={false}
                  showNav={false}
                  showFullscreenButton={false}
                  useBrowserFullscreen={false}
                  showThumbnails={false}
                  showBullets={true}
                  onScreenChange={(isFullScreen) => setShowProductImageInFullScreen(isFullScreen)}
                  onClick={() => productPhotosRef.current.toggleFullScreen()} />
              </div>
              <div className="col-span-2 space-y-4 text-center lg:text-left lg:col-span-1">
                <h1 className='font-semibold text-content-regular text-3xl sm:text-4xl xl:text-5xl'>Allay Peanut Capsule</h1>
                <p className='text-lg sm:text-xl'>Allay offers a three-step program: Adapt, Build, and Maintain, designed to help your toddlers safely increase their tolerance to peanuts.</p>
                <div className='inline-block space-x-2 pt-4'>
                  <button className='btn-toggle' disabled={program === "ADAPT"} onClick={() => setProgramPhase("ADAPT")}>Adapt</button>
                  <button className='btn-toggle' disabled={program === "BUILD"} onClick={() => setProgramPhase("BUILD")}>Build</button>
                  <button className='btn-toggle' disabled={program === "MAINTAIN"} onClick={() => setProgramPhase("MAINTAIN")}>Maintain</button>
                </div>
                
                {program === "ADAPT" && 
                  <p>
                    <span className='italic pr-1'>Start with 2mg, this phase helps your child reach a baseline level of peanut tolerance.</span>
                    <span className='underline cursor-pointer' onClick={() => navigate(MILESTONES_URL, {state: {anchor: 'adapt'}})}>Learn more</span>
                  </p>}
                {program === "BUILD" && 
                  <p>
                    <span className='italic pr-1'>Next, we work to desensitize their body to peanuts by gradually increase their peanut flour intakes up to 768mg.</span>
                    <span className='underline cursor-pointer' onClick={() => navigate(MILESTONES_URL, {state: {anchor: 'build'}})}>Learn more</span>
                  </p>}
                {program === "MAINTAIN" && 
                  <p>
                    <span className='italic pr-1'>This final step ensures that their body maintains its newfound peanut tolerance indefinitely!</span>
                    <span className='underline cursor-pointer' onClick={() => navigate(MILESTONES_URL, {state: {anchor: 'maintain'}})}>Learn more</span>
                  </p>}

                <div className="text-left mb-6">
                  {products.map((product, index) => {
                    if (product.phase === program) {
                      return (
                        <ProductRow
                          key={index}
                          selected={product.id  === selectedProduct.id}
                          onClick={() => setSelectedProduct(product)}
                          product={product} />
                      );
                    } else return <></>
                  })}
                </div>

                <ProductPageSupplementFactsSection product={selectedProduct} />
                <ProductPageIngredientsSection product={selectedProduct} />
                <ProductPageHowSuggestedUseSection />

                {!showProductImageInFullScreen &&
                  <div className='sticky bottom-0 hidden lg:block'>
                    <div className='bg-brand-primary-regular py-4'>
                      <button className="btn-primary w-full" disabled={selectedProduct.unavailable_until} onClick={() => navigate(CHECKOUT_URL, { state: { product: selectedProduct } })}>
                        {selectedProduct.unavailable_until 
                          ? `Available on ${moment(selectedProduct.unavailable_until).format("MMM DD, YYYY")}`
                          : `Checkout - $${selectedProduct.price}`
                        }
                      </button>
                      <div className="text-left flex space-x-2 pt-2 md:space-x-3 xl:space-x-6 justify-center">
                        <p className="flex text-sm"><span className="text-brand-secondary-regular pr-1 xl:pr-2"><AiFillCheckCircle size="18" /></span>100% Money-back guaranteed</p>
                        <p className="flex text-sm"><span className="text-brand-secondary-regular pr-1 xl:pr-2"><AiFillCheckCircle size="18" /></span>Free shipping</p>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <ProductPagePropsSection />
          {/* Hide these detail fullscreen carousel is lower z-index than these elements */}
          {!showProductImageInFullScreen && 
          <div>
            <ProductPageFAQSection />
            <div className='fixed bottom-0 left-0 right-0 lg:hidden'>
                <div className='bg-brand-primary-regular mt-5 shadow-[rgba(0,0,0,0.1)_0px_-6px_3px_0px]'>
                <div className='app-container py-0'>
                  <div className='section-container py-4 space-y-0'>
                    <button className="btn-primary w-full" disabled={selectedProduct.unavailable_until} onClick={() => navigate(CHECKOUT_URL, { state: { product: selectedProduct } })}>
                      {selectedProduct.unavailable_until 
                        ? `Available on ${moment(selectedProduct.unavailable_until).format("MMM DD, YYYY")}`
                        : `Checkout - $${selectedProduct.price}`
                      }
                    </button>                    
                    <div className="text-left flex space-x-2 pt-2 md:space-x-3 xl:space-x-6 justify-center">
                      <p className="flex text-sm"><span className="text-brand-secondary-regular pr-1 xl:pr-2"><AiFillCheckCircle size="18" /></span>100% Money-back guaranteed</p>
                      <p className="flex text-sm hidden sm:flex"><span className="text-brand-secondary-regular pr-1 xl:pr-2"><AiFillCheckCircle size="18" /></span>Free shipping</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      }
      <DefaultFooter />
    </div >
  )
}

function ProductPageHowSuggestedUseSection() {
  return (
    <div className="section-container px-0 py-4 space-y-4 text-left">
      <p className="text-xl font-medium">Suggested use</p>
      <ul className="list-disc pl-6 space-y-2">
        <li>Take the capsule directly or break it up and mix the powder with food</li>
        <li>Monitor any reactions for at least 2 hours</li>
        <li>Report consumption and any reactions to Allay app</li>
        <li>Get notified when it’s safe to transition to a higher dosage</li>
      </ul>
    </div>
  )
}

function ProductPageSupplementFactsSection(props) {
  return (
    <div className="section-container px-0 py-4 space-y-4 text-left">
      <p className="text-xl font-medium">Supplement facts</p>
      <p>Serving size: {props.product.capsules_per_serving} capsule. {props.product.num_serving} servings per container.</p>
      <div>
        {props.product.serving_supplement_composition.supplement_facts.map((fact, i) =>
          <div className="flex py-2 border-b border-content-regular">
            <p className="flex-grow">{fact.name}</p>
            <p>{fact.amount}</p>
          </div>
        )}
      </div>
    </div>
  )
}

function ProductPageIngredientsSection(props) {
  return (
    <div className="section-container px-0 py-4 space-y-4 text-left">
      <p className="text-xl font-medium">Ingredients</p>
      <div className="space-y-2">
        <p>{props.product.serving_supplement_composition.ingredient_contains}</p>
        <p>Does not contain: {props.product.serving_supplement_composition.ingredient_excludes}</p>
      </div>
    </div>
  )
}

function ProductPagePropsSection() {
  return (
    <div className="section-container bg-brand-secondary-regular">
      <div className="grid grid-cols-12">
        <div className="space-y-4 text-center py-4 col-start-auto col-span-4 lg:col-start-2 lg:col-span-2 px-4 xl:px-8">
          <img className="object-cover w-2/3 mx-auto" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1694224467/prod_props_science_backed.svg" alt="science backed" />
          <p className="font-semibold">Backed by science</p>
        </div>
        <div className="space-y-4 text-center py-4 col-span-4 lg:col-span-2 px-4 xl:px-8">
          <img className="object-cover w-2/3 mx-auto" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1694224661/Frame_416_q3sq68.svg" alt="natural ingredients" />
          <p className="font-semibold">No Artificial Ingredients</p>
        </div>
        <div className="space-y-4 text-center py-4 col-span-4 lg:col-span-2 px-4 xl:px-8">
          <img className="object-cover w-2/3 mx-auto" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1694224742/Frame_418_dulmu0.svg" alt="Vegan" />
          <p className="font-semibold">Vegan</p>
        </div>
        <div className="space-y-4 text-center py-4 col-start-3 col-span-4 lg:col-start-auto lg:col-span-2 px-4 xl:px-8">
          <img className="object-cover w-2/3 mx-auto" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1694224757/Frame_412_a8mg6d.svg" alt="Non-GMO" />
          <p className="font-semibold">Non-GMO</p>
        </div>
        <div className="space-y-4 text-center py-4 col-span-4 lg:col-span-2 px-4 xl:px-8">
          <img className="object-cover w-2/3 mx-auto" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1694224877/Frame_420_oqwxcd.svg" alt="Mobile app" />
          <p className="font-semibold">Companion mobile app</p>
        </div>
      </div>
    </div>
  )
}

function ProductPageFAQSection() {
  let navigate = useNavigate();

  return (
    <div className="section-container bg-brand-primary-regular py-14 overflow-hidden lg:overflow-visible">
      <div className="grid grid-cols-2 md:gap-8 xl:gap-16 lg:pb-16">
        <div className="col-span-2 lg:sticky lg:self-start lg:top-0 lg:col-span-1">
          <p className="text-xl font-semibold text-content-regular pt-6">Still need some things answered?</p>
          <h3 className="text-3xl font-semibold text-content-regular pt-2 pb-8">Frequently Asked Questions </h3>
        </div>
        <div className="col-span-2 text-left lg:col-span-1">
          <ExpandableFAQItem
            question="What does the 100% Money-back guaranteed entail, and how can I request a refund?            "
            expanded={true}>
            <p className="text-content-regular pt-4">If you're not satisfied with our product, you can request a full-refund within 30 days of purchase through our Allay app — no questions asked.</p>
          </ExpandableFAQItem>
          <ExpandableFAQItem
            question="What are the potential side effects when starting Allay?">
            <p className="text-content-regular pt-4">As Allay contains a carefully controlled amount of peanuts, initial consumption or increases in dosage might cause mild reactions in some individuals, especially those with known peanut sensitivities. While severe reactions are extremely rare, they are more likely to occur during initial introduction to Allay or upon dosage increases.</p>
            <p className="text-content-regular pt-4">Please exercise caution during the first few days of any dosage change. If anyone taking Allay shows signs of difficulty breathing, immediately administer an EpiPen or similar epinephrine autoinjector and seek emergency medical attention. Always consult with a healthcare professional before starting or changing your Allay regimen.</p>
          </ExpandableFAQItem>
          <ExpandableFAQItem
            question="When can I expect to see results from using Allay?">
            <p className="text-content-regular pt-4">While individual experiences can vary, some people may start to notice changes as early as the first week, even if they are not immediately apparent due to the controlled quantity in Allay. Please refer to the <span className="underline font-semibold" onClick={() => navigate(MILESTONES_URL)}> milestone chart</span> for a better understanding of the typical progression. As always, consult your healthcare professional with any questions or concerns.</p>
          </ExpandableFAQItem>
          <ExpandableFAQItem
            question="What is the recommended duration for using Allay?">
            <p className="text-content-regular pt-4">Allay is structured around three phases, and the usage duration can vary depending on your individual progress and tolerance. While early use might not lead to complete remission for everyone, we generally recommend maintaining consistent use of Allay for optimal long-term results and peace of mind. As always, consult your healthcare professional for personalized advice.</p>
          </ExpandableFAQItem>
          <ExpandableFAQItem
            question="How does the Allay app work?">
            <p className="text-content-regular pt-4">Our Allay app is designed as a convenient tool to log your product usage and any reactions, and receive AI-driven dosage recommendations. It also ensures a seamless auto-refill and ordering process, making your journey with Allay easier and more manageable.</p>
          </ExpandableFAQItem>
          <button className="mt-6 underline" onClick={() => navigate(FAQ_URL)}>Read all</button>
        </div>
      </div>
    </div>
  )
}

function ProductRow(props) {
  return (
    <div className={`cursor-pointer p-4 mt-4 rounded-xl border-solid ${props.selected ? "border-2 border-content-regular bg-brand-primary-light" : "border border-content-lighter"}`} onClick={props.onClick}>
      <p className='font-semibold'>{props.product.concentration_mg}mg organic peanut capsule</p>
      <p className="font-content text-content-light">1 bottle · {props.product.num_capsules} capsules</p>
      { props.product.unavailable_until 
        ? <p>Available on {moment(props.product.unavailable_until).format("MMM DD, YYYY")}</p>
        : <p className='pt-2 text-xl font-semibold'>${currencyFormatter.format(props.product.price, "USD")}</p>
      }
    </div>
  )
}
