import React, { useState } from 'react'
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { PreSignInHeader, DefaultFooter } from './common/HeaderAndFooter';
import InputField from "./common/InputField";
import { ALLAY_TOKEN } from './common/Cookie';
import API from '../api';
import { ACCOUNT_URL } from './common/Navigation';


export default function AccountBillingEditPage(props) {
  let navigate = useNavigate();

  const { state } = useLocation();
  const [cookies] = useCookies([ALLAY_TOKEN]);

  const BILLING_VALIDATION_ERROR_MSG = "Please enter a valid payment method";
  const BILLING_VALIDATION_ADDRESS_ERROR_MSG = "Please enter a valid billing address";
  const BILLING_UPDATE_INTERNAL_ERROR_MSG = "Oops, looks like something went wrong. Please try again."
  const [billingError, setBillingError] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpirationDate, setCardExpirationDate] = useState('');
  const [cardSecurityCode, setCardSecurityCode] = useState('');
  const [billingFirstName, setBillingFirstName] = useState(state.billing_first_name);
  const [billingLastName, setBillingLastName] = useState(state.billing_last_name);
  const [billingStreet1, setBillingStreet1] = useState(state.billing_street1);
  const [billingStreet2, setBillingStreet2] = useState(state.billing_street2 || '');
  const [billingCity, setBillingCity] = useState(state.billing_city);
  const [billingRegion, setBillingRegion] = useState(state.billing_region);
  const [billingPostalCode, setBillingPostalCode] = useState(state.billing_postal_code);
  const [disabledCta, setDisableCta] = useState(false);
  const [didFinishUpdated, setDidFinishUpdated] = useState(false);

  function handleEditBillingInfo() {
    if (validateBilling()) {
      setDisableCta(true);
      let expirationMonthAndYear = cardExpirationDate.split("/");

      API.updateUserBillingInfo(cookies[ALLAY_TOKEN], {
        "card_number": cardNumber.replace(/[^\d]/g, ""),
        "card_expiration_month": expirationMonthAndYear[0],
        "card_expiration_year": expirationMonthAndYear[1],
        "card_security_code": cardSecurityCode,
        "billing_first_name": billingFirstName,
        "billing_last_name": billingLastName,
        "billing_street1": billingStreet1,
        "billing_street2": billingStreet2,
        "billing_city": billingCity,
        "billing_region": billingRegion,
        "billing_postal_code": billingPostalCode
      }).then(response => {
        if (response.ok) {
          setDidFinishUpdated(true);
        } else {
          throw response;
        }
      }).catch(e => {
        setBillingError(BILLING_UPDATE_INTERNAL_ERROR_MSG);
        setDisableCta(false);
        console.log(e);
      });
    }
  }

  function handleCardNumberChange(event) {
    var ccNumber = event.target.value.replace(/[^\d]/g, "");
    ccNumber = ccNumber.substring(0, Math.min(16, ccNumber.length));

    var parts = [];
    for (var i = 0; i < ccNumber.length; i += 4) {
      parts.push(ccNumber.substring(i, i + 4));
    }
    if (parts.length) {
      ccNumber = parts.join(' ');
    }

    setCardNumber(ccNumber);
  }

  function handleCardExpirationDateChange(event) {
    var expiration = event.target.value.replace(/[^\d]/g, "");
    expiration = expiration.substring(0, Math.min(6, expiration.length));

    if (expiration.length > 2) {
      setCardExpirationDate(expiration.substring(0, 2) + '/' + expiration.substring(2));
    } else {
      setCardExpirationDate(expiration);
    }
  }

  function validateBilling() {
    setBillingError("");

    // Check if the credit card number has the correct format
    const regex = /^[0-9]{16}$/;
    if (!regex.test(cardNumber.replace(/[^\d]/g, ""))) {
      setBillingError(BILLING_VALIDATION_ERROR_MSG);
      return false;
    }

    // Check if the expiration date is valid
    let expirationMonthAndYear = cardExpirationDate.split("/");
    if (expirationMonthAndYear.length !== 2) {
      setBillingError(BILLING_VALIDATION_ERROR_MSG);
      return false;
    }
    var date = new Date();
    date.setFullYear(expirationMonthAndYear[1], expirationMonthAndYear[0], 1);
    if (date.getTime() < new Date().getTime()) {
      setBillingError(BILLING_VALIDATION_ERROR_MSG);
      return false;
    }

    if (!validateBillingAddress) {
      setBillingError(BILLING_VALIDATION_ADDRESS_ERROR_MSG);
      return false;
    }

    return true;
  }

  function validateBillingAddress() {
    const postalCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
    return billingFirstName.length > 0 && billingLastName.length > 0 && billingStreet1.length > 4 && billingCity.length > 2 && billingRegion.length > 1 && postalCodeRegex.test(billingPostalCode);
  }

  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container relative rounded-t-3xl bg-brand-primary-regular text-center content-center py-16">
          <div className="max-w-lg mx-auto space-y-8">
            <div className="text-left md:bg-brand-primary-light md:rounded-2xl md:w-full md:p-8">
              {didFinishUpdated
                ? <div className="space-y-4 text-center">
                  <p className="font-semibold">Billing information updated</p>
                  <button className="btn-outline" onClick={() => navigate(ACCOUNT_URL)}>Go back</button>
                </div>
                : <div className="space-y-4">
                  <p className="font-semibold pb-2 uppercase text-center">Edit your billing information</p>
                  <InputField
                    label="Card number"
                    value={cardNumber}
                    name="cardnumber"
                    type="tel"
                    autocomplete="cc-number"
                    x_autocompletetype="cc-number"
                    onChange={handleCardNumberChange} />
                  <div className="flex space-x-4 pb-8">
                    <InputField
                      className="flex-grow"
                      label="Expiration (MM/YYYY)"
                      value={cardExpirationDate}
                      name="cardexpiration"
                      type="tel"
                      autocomplete="cc-exp"
                      x_autocompletetype="cc-exp"
                      onChange={handleCardExpirationDateChange} />
                    <InputField
                      className="max-w-35pc"
                      label="CVC"
                      value={cardSecurityCode}
                      name="cardcvc"
                      type="number"
                      autocomplete="cc-csc"
                      x_autocompletetype="cc-csc"
                      onChange={(event) => setCardSecurityCode(event.target.value)} />
                  </div>

                  <p className="text-xs text-center font-semibold text-content-light pb-2 uppercase">Billing address</p>
                  <div className="flex space-x-4">
                    <InputField
                      className="flex-grow"
                      label="First name"
                      name="billingFirstName"
                      value={billingFirstName}
                      type="text"
                      autocomplete="given-name"
                      x_autocompletetype="given-name"
                      onChange={(event) => setBillingFirstName(event.target.value)} />
                    <InputField
                      className="flex-grow"
                      label="Last name"
                      name="billingLastName"
                      value={billingLastName}
                      type="text"
                      autocomplete="family-name"
                      x_autocompletetype="family-name"
                      onChange={(event) => setBillingLastName(event.target.value)} />
                  </div>
                  <InputField
                    className="w-full"
                    label="Address"
                    name="billingAddress1"
                    value={billingStreet1}
                    type="text"
                    autocomplete="address-line1"
                    x_autocompletetype="address-line1"
                    attention={billingError.length > 0}
                    onChange={(event) => setBillingStreet1(event.target.value)} />
                  <InputField
                    className="w-full"
                    label="Apt, suite, etc. (optional)"
                    name="billingAddress2"
                    value={billingStreet2}
                    type="text"
                    autocomplete="address-line2"
                    x_autocompletetype="address-line2"
                    onChange={(event) => setBillingStreet2(event.target.value)} />
                  <InputField
                    className="w-full"
                    label="City"
                    name="billingCity"
                    value={billingCity}
                    type="text"
                    autocomplete="locality"
                    x_autocompletetype="locality"
                    onChange={(event) => setBillingCity(event.target.value)} />
                  <div className="flex space-x-4 pb-8">
                    <InputField
                      className="flex-grow"
                      label="State"
                      name="billingRegion"
                      value={billingRegion}
                      type="text"
                      autocomplete="region"
                      x_autocompletetype="region"
                      onChange={(event) => setBillingRegion(event.target.value)} />
                    <InputField
                      className="max-w-50pc"
                      label="Zip code"
                      name="billingPostalCode"
                      value={billingPostalCode}
                      type="text"
                      autocomplete="postal-code"
                      x_autocompletetype="postal-code"
                      onChange={(event) => setBillingPostalCode(event.target.value)} />
                  </div>
                  <div className="space-y-2">
                    {billingError.length > 0 &&
                      <p className="text-content-error">{billingError}</p>
                    }
                    <button className="btn-primary w-full" disabled={disabledCta} onClick={handleEditBillingInfo}>Save</button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  )
}