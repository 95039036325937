import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultFooter, PreSignInHeader } from './common/HeaderAndFooter';
import { MILESTONES_URL } from './common/Navigation';

export default function FaqPage() {
  let navigate = useNavigate();

  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container relative rounded-t-3xl bg-brand-primary-regular py-16 md:py-20">
          <h3 className="text-3xl font-semibold text-center text-content-regular pt-2 pb-4">Frequently Asked Questions </h3>
          <div className="text-left max-w-xl mx-auto">
            <ExpandableFAQItem
              question="What are the potential side effects when starting Allay?"
              expanded={true}>
              <p className="text-content-regular pt-4">As Allay contains a carefully controlled amount of peanuts, initial consumption or increases in dosage might cause mild reactions in some individuals, especially those with known peanut sensitivities. While severe reactions are extremely rare, they are more likely to occur during initial introduction to Allay or upon dosage increases.</p>
              <p className="text-content-regular pt-4">Please exercise caution during the first few days of any dosage change. If anyone taking Allay shows signs of difficulty breathing, immediately administer an EpiPen or similar epinephrine autoinjector and seek emergency medical attention. Always consult with a healthcare professional before starting or changing your Allay regimen.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="Can Allay interact with other medications or foods?">
              <p className="text-content-regular pt-4">Allay is a dietary supplement and is typically safe to consume alongside other medications or foods. However, it's crucial to ensure that these do not contain peanuts to avoid potential overexposure. Always consult your healthcare professional when introducing a new dietary supplement like Allay to your routine.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="How long can I keep my Allay products?">
              <p className="text-content-regular pt-4">Each bottle of Allay typically has a shelf life of at least one year. Please check the expiration date on your bottle to ensure optimal quality and effectiveness.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="When can I expect to see results from using Allay?">
              <p className="text-content-regular pt-4">While individual experiences can vary, some people may start to notice changes as early as the first week, even if they are not immediately apparent due to the controlled quantity in Allay. Please refer to the <span className="underline font-semibold" onClick={() => navigate(MILESTONES_URL)}>milestone chart</span> for a better understanding of the typical progression. As always, consult your healthcare professional with any questions or concerns.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="How long can I keep my Allay products?">
              <p className="text-content-regular pt-4">Each bottle of Allay typically has a shelf life of at least one year. Please check the expiration date on your bottle to ensure optimal quality and effectiveness.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="I just started using Allay and I'm having a severe reaction. What should I do?">
              <p className="text-content-regular pt-4">If you're experiencing a severe reaction, please seek emergency medical help immediately. Your safety is our top priority. Once everything is stable, please get in touch with us so we can assist you with the next steps.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="I just started using Allay and I'm experiencing a mild reaction. What should I do?">
              <p className="text-content-regular pt-4">Mild reactions are expected in some cases as your body adjusts. If the reactions are manageable, it's likely that your current dosage is appropriate. Please continue with your routine but ensure to log any reactions in our app. If symptoms worsen, please consult with your healthcare professional.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="I just started using Allay and I haven't noticed any changes. Is it working?">
              <p className="text-content-regular pt-4">If you're not experiencing any adverse reactions, that could be a positive sign! It suggests you're tolerating the prescribed amount of peanut in Allay. Keep going with your routine, and always consult your healthcare professional with any questions or concerns about your progress.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="How long should I continue to use Allay?">
              <p className="text-content-regular pt-4">Allay is designed with three distinct phases in mind, and the duration of use can vary depending on individual experiences. While some may not see remission if they start early in life, it's generally recommended to continue as part of a routine for long-term well-being. Using Allay consistently may contribute towards lifelong peace of mind. Please consult your healthcare professional for personalized advice.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="What happens if I stop using Allay?">
              <p className="text-content-regular pt-4">Temporarily discontinuing Allay might not cause immediate changes. However, prolonged periods without Allay could increase the chances of your body reverting to its previous response to peanuts. As always, consult with your healthcare professional before making any changes to your routine.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="What if I miss a dose of Allay?">
              <p className="text-content-regular pt-4">Don't worry if you miss a dose. Just make sure to record it in the app and then continue with your usual dosage the next day. It's important to keep track of your usage for the most effective journey with Allay.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="I'm planning to consume peanuts today, or I already have. What should I do about my Allay dose?">
              <p className="text-content-regular pt-4">If you're going to eat peanuts or you already have, feel free to skip your Allay dose for the day. After all, Allay is designed to support you in situations like these. Continue with your normal routine the following day.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="What is the recommended duration for using Allay?">
              <p className="text-content-regular pt-4">Allay is structured around three phases, and the usage duration can vary depending on your individual progress and tolerance. While early use might not lead to complete remission for everyone, we generally recommend maintaining consistent use of Allay for optimal long-term results and peace of mind. As always, consult your healthcare professional for personalized advice.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="Can I eat peanuts while using Allay?">
              <p className="text-content-regular pt-4">Some limited research and statements suggest that consuming up to three times the daily dose of peanut allergen may be tolerated with minimal reactions. However, given the limited data and inability to confirm the validity of these statements, we recommend not exceeding 50% of your daily Allay dose from additional sources. This is why our dosage increments are designed to increase by no more than 50% at each step. Always consult your healthcare professional for personalized advice.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="When is the best time to take Allay?">
              <p className="text-content-regular pt-4">We recommend taking Allay when you'll have about two hours to monitor for any potential reactions. For many families, this aligns well with dinner time so you can also mix Allay into your food to make consumption easier. Remember, always consult your healthcare professional for personalized advice.</p>
            </ExpandableFAQItem>
            <ExpandableFAQItem
              question="How does the Allay app work?">
              <p className="text-content-regular pt-4">Our Allay app is designed as a convenient tool to log your product usage and any reactions, and receive AI-driven dosage recommendations. It also ensures a seamless auto-refill and ordering process, making your journey with Allay easier and more manageable.</p>
            </ExpandableFAQItem>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  )
}


export function ExpandableFAQItem(props) {
  const [isExpanded, setIsExpanded] = useState(props.expanded || false);

  function handleExpandToggle() {
    setIsExpanded(!isExpanded);
  }

  return (
    <div className="cursor-pointer py-6 border-b border-content-regular" onClick={handleExpandToggle}>
      <div className="flex flex-row space-x-8">
        <p className="text-xl text-content-regular font-medium flex-grow">{props.question}</p>
        <p className="text-xl text-content-regular font-semibold">{isExpanded ? '-' : '+'}</p>
      </div>
      {isExpanded && props.children}
    </div>
  )
}