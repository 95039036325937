import React from 'react'
import { useNavigate } from "react-router-dom";
import { DefaultFooter, PreSignInHeader } from './common/HeaderAndFooter'
import { HOME_URL, PRIVACY_OPT_OUT_URL, TERMS_URL } from './common/Navigation';
import { COMPANY_NAME, COMPANY_ADDRESS_STREET, COMPANY_ADDRESS_REGION } from './TermsPage';
import { GetRequestParam } from './common/Util';

const COMPANY_DATA_PRIVACY_EMAIL = "data-privacy@getallay.com";
const COMPANY_OPT_OUT_LINK = "https://getallay.com/privacy-opt-out";

export default function PrivacyPage() {
  const is_mobile = GetRequestParam("device") === 'mobile';
  const mode = GetRequestParam("mode");

  if (is_mobile) return <MobilePrivacyPage mode={mode} />;
  else return <WebPrivacyPage />;
}

function WebPrivacyPage() {
  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container relative rounded-t-3xl bg-brand-primary-regular py-16 md:py-20">
          <Privacy />
        </div>
      </div>
      <DefaultFooter />
    </div>
  );
}

function MobilePrivacyPage(props) {
  return (
    <div className={`${props.mode === 'dark' ? 'bg-brand-primary-regularOnDark dark-mode' : 'bg-brand-primary-regular'}`}>
      <div className="app-container py-4">
        <Privacy />
      </div>
    </div>
  );
}

function Privacy() {
  let navigate = useNavigate();

  return (
    <div className="max-w-xl mx-auto">
      <h1 className="text-3xl pb-4 flex items-center">Privacy Policy</h1>
      <div className="space-y-4">
        <p>"This Privacy Policy details the methods in which personal information about you is collected, used, disclosed, and otherwise processed by {COMPANY_NAME}, Inc. and its affiliates (“{COMPANY_NAME},” “we,” “our,” or “us”) concerning your use and interaction with {COMPANY_NAME} through our website <span className="btn-inline" onClick={() => navigate(HOME_URL)}>getallay.com</span> (the “Website”), our mobile application (the “Application”), the services accessible through our Website and Application, and via other online activities involving {COMPANY_NAME} products and services of {COMPANY_NAME} affiliates (collectively, the “Services”). This Privacy Policy also extends to the personal information we gather offline.</p>
        <p>Your use of our Services, and any conflict over privacy, is subject to this Privacy Policy and our <span className="btn-inline" onClick={() => navigate(TERMS_URL)}>Terms of Use</span>, which include its pertinent limitations on damages and the resolution of disputes.</p>

        <p className="p-heading">Personal Information</p>
        <p>Personal information is data that identifies, pertains to, describes, references, is reasonably capable of being linked with, or could logically be connected, directly or indirectly, with a particular consumer or household.</p>

        <p className="p-heading">Information We Collect About You</p>
        <p><span className="italic">Information We Collect Directly from You.</span> The data we collect from you depends on how you use our Services. Information we collect may include:</p>
        <ul className="space-y-4 pl-4 list-inside list-disc">
          <li className="content">When responding to inquiries or communications you send us, we collect contact details, such as your name, e-mail address, postal address, and phone number. We may also collect records and copies of your correspondence, product order information, and other information you choose to share with us, such as information regarding events related to the products or services you requested or purchased from us.</li>
          <li className="content">We gather the information you provide when you complete a survey, questionnaire, or other types of forms via the Services, including information about your health and wellness, health goals, diet, lifestyle, location data, demographic information, your views about health and wellness, and medical conditions that you have experienced or that exist in your family.</li>
          <li className="content">When you create an account, we collect contact information, such as your name, e-mail address, username, password, mailing address, and phone number.</li>
          <li className="content">When you request or purchase products or services from us, we collect information such as your name, e-mail address, shipping and billing address, phone number, order information, including details of products and subscriptions you opt to purchase, and payment information.</li>
          <li className="content">While you are navigating the Website or using the Services, we may also collect your search queries, information about your viewing history, and other data regarding your use and interaction with the Services for customer or technical support purposes, or to aid in improving our Services.</li>
          <li className="content">When you use certain features of our Services, we collect information about your health supplement intake and frequency to provide you with personalized recommendations (such as personalized health supplement recommendations).</li>
          <li className="content">We may also use your contact information and mailing address for other loyalty or rewards program purposes, such as sending gift packages to you.</li>
          <li className="content">We may respond to or engage with social media posts or messages directed at an Allay account, or which reference our products. If you publicly share content about us or our products, we may opt to re-post or otherwise share that content with our followers, subject to the terms of the relevant social media platform.</li>
          <li className="content">We also collect information that may be reported to government authorities or regulators in compliance with applicable requirements. This information may include your contact information, as well as details of any adverse event, which may include medical information.</li>
        </ul>
        <p><span className="italic">Information We Collect from Other Entities.</span> Information we collect from other entities may be merged with other information we have collected about you in order to complete requests you have made or facilitate transactions you have initiated via the Services, or for other purposes, such as fraud prevention, authentication, order management, to supplement customer profiles, and for marketing purposes. Information we may collect about you from third-party sources may include your name, e-mail address, mailing address, phone number, and other contact information.</p>
        <p><span className="italic">Information We Collect Automatically.</span> When you visit our Website, we collect technical data including the name of your internet service provider, the website that you used to link to our Website, links you click on our Website, and your IP address. We may also collect information about the duration you spent visiting our Website and/or using our Services, the referring URL, or the web pages that led you to our Website. We use this data occasionally for statistical purposes, including use of information in de-identified and aggregated format. Please see the Use of Cookies and Other Tracking Mechanisms section below for more details.</p>

        <p className="p-heading">How We Use Your Information</p>
        <p>We use your data, including your personal information, for the following purposes:</p>
        <ul className="space-y-4 pl-4 list-inside list-disc">
          <li className="content">To provide and maintain our Services.</li>
          <li className="content">To establish and uphold our business relationship with you.</li>
          <li className="content">To notify you about alterations to our Services.</li>
          <li className="content">To communicate with you about your use of our Services, including sending reminders, responding to your inquiries, and for other customer service purposes.</li>
          <li className="content">To customize the content and information that we may send or display to you, to offer location customization and personalized help and instructions, and to otherwise personalize your experiences while using the Services. We also leverage the information we collect in aggregate to deliver more relevant advertisements and offers to you on other third-party websites as you browse the Internet.</li>
          <li className="content">To provide you with information that we believe to be of interest to you, including health or wellness-related information or services.</li>
          <li className="content">To send you e-mail marketing materials about our products and services.</li>
          <li className="content">To send you news and newsletters.</li>
          <li className="content">To better understand how users access and use our Services, both on an aggregated and individualized basis.</li>
          <li className="content">To handle inquiries and complaints you or other users of our Services submit to us.</li>
          <li className="content">To administer surveys and questionnaires, such as for market research or user satisfaction purposes, and for obtaining health and wellness information.</li>
          <li className="content">To comply with legal obligations, as part of our general business operations, and for other business administration purposes, such as maintaining customer records, monitoring your compliance with any of your agreements with us, collecting debts owed to us, and protecting our business interests.</li>
          <li className="content">To verify that you meet the eligibility requirements for purchasing or receiving our Services.</li>
          <li className="content">Where we believe it necessary to investigate, prevent or take action regarding illegal activities, suspected fraud, and in situations involving potential threats to the safety of any person or violations of our Terms of Use or our Privacy Policy.</li>
          <li className="content">For research and analytics purposes, including analyzing data, identifying trends and issues and creating statistical reports, analyzing the accuracy and effectiveness of our Website and the Services, including administering, troubleshooting, and enhancing and improving the Website and the Services.</li>
          <li className="content">For quality assurance and regulatory compliance purposes.</li>
          <li className="content">To fulfill any other purpose for which you provide the information.</li>
          <li className="content">For any other purpose for which we provide you notice, or you provide us with your consent.</li>
        </ul>
        <p>We may also store or transfer your personal information outside of your country of residence. See the How We Store Your Personal Information section for more details about how we store your information. See the Your Options section to learn what choices you have regarding our collection and use of your personal information.</p>

        <p className="p-heading">How We Share Your Information</p>
        <p>Your information, inclusive of personal data such as the responses you provide in forms, quizzes, or surveys submitted through our Services, may be shared in the following ways:</p>
        <p><span className="italic">Third-Party Service Providers:</span> We may share the information we collect from you with third-party service providers, such as customer management providers, IT services providers, data storage providers, technical support providers, payment processors, and shipping providers. These entities assist us in providing our Services, analyzing the usage of our Services, and delivering other services.</p>
        <p><span className="italic">Subsidiary Entities and Affiliates:</span> The information we collect from you may be disclosed to our subsidiaries or affiliates, including Bayer, as necessary to deliver our Services, and for marketing, advertising, analytics, and measurement purposes.</p>
        <p><span className="italic">With Other Entities:</span> We may share your information with other entities, granted that you've given us your permission.</p>
        <p><span className="italic">Promotional Partners:</span> We may occasionally share your personal information with promotional and marketing partners who may send promotional or marketing communications on our behalf, and about their own products or services, about the products and services of other third parties, or for their marketing, advertising, analytics, and measurement purposes.</p>
        <p>Furthermore, your personal information may be shared under the following circumstances:</p>
        <p><span className="italic">Legal Requirements:</span> We may disclose personal information when legally authorized or required, such as in response to court orders, subpoenas, or other legal processes enforced by authorities with jurisdiction. We may also disclose personal information to meet our obligations to health and drug regulators.</p>
        <p><span className="italic">Business Transfers:</span> In the event of a merger, consolidation, change in control, asset transfer, bankruptcy, reorganization, liquidation, or other business transactions, we may transfer or assign your personal information.</p>
        <p><span className="italic">Protection Measures:</span> We reserve the right to disclose your information when it is appropriate to investigate, prevent or take action regarding illegal activities, suspected fraud, potential threats to the safety of any person, violations of our Terms of Use or this Privacy Statement, or as evidence in litigation in which we are involved.</p>
        <p><span className="italic">Aggregate and De-Identified Information:</span> We share aggregated or de-identified information about users with third parties for marketing, advertising, research, or similar purposes.</p>

        <p className="p-heading">Storage of Your Information</p>
        <p>Your personal information is stored with data processors or on servers owned by us or those acting on our behalf and accountable to us.</p>
        <p>Some of this data might be stored or processed on computers or servers located in jurisdictions other than your country of residence, which might have different data protection laws than those of your country. Consequently, your personal information may be accessible to law enforcement and/or regulatory authorities according to the laws of that jurisdiction.</p>

        <p className="p-heading">Retention of Your Information</p>
        <p>We retain personal information only for as long as reasonably necessary to provide a service you have requested or consented to, and for business and/or legal reasons (e.g., retention periods determined in relation to potential litigation, etc.).</p>

        <p className="p-heading">Use of Cookies and Additional Tracking Tools</p>
        <p>As is standard with many websites and online services, we automatically gather technical data when you visit and use our Website.</p>
        <p><span className="italic">Cookies:</span> Cookies, along with similar technologies, are identifiers enabling our Website to provide Services to you. We use cookies to collect data about how users navigate our Website, personalize content, and provide targeted ads through third-party advertising networks when you visit other sites. We use two kinds of cookies:</p>
        <p><span className="italic">Session Cookies:</span>  These cookies only exist during an online session and disappear from your device when you close your browser or shut down your device. We use session cookies to uniquely identify you during a session or when you are logged into the Services, which allows us to process your online transactions and requests and verify your identity as you interact with our Services.</p>
        <p><span className="italic">Persistent Cookies:</span> These cookies stay on your device even after you've closed your browser or turned off your device. We use persistent cookies to track aggregate and statistical information about user activity.</p>
        <p><span className="italic">Cookie Management:</span> Most web browsers accept cookies automatically, but you can modify your browser settings to block them if you prefer. Instructions on preventing your computer from accepting new cookies, having the browser notify you when you receive a new cookie, or disabling cookies altogether can usually be found in the Help section of your browser's toolbar. Visitors who disable cookies can still browse certain areas of the Website, but some features may not function.</p>
        <p><span className="italic">Clear GIFs, Pixel Tags and Additional Technologies:</span> Clear GIFs, tiny graphics with a unique identifier functioning similarly to cookies, are embedded invisibly on web and app pages. We may use clear GIFs (also known as web beacons or pixel tags) in connection with our Services to track Website visitors' activities, manage content, and compile statistics about Website usage. We and our third-party service providers also use clear GIFs in HTML emails to our customers to help track email response rates, identify when our emails are viewed, and track whether our emails are forwarded.</p>
        <p><span className="italic">Third-Party Analytics:</span> We use automated devices and applications, such as Google Analytics, to evaluate our Website's usage. We use these tools to enhance our Website's performance, our Services, and user experiences. These entities may use cookies and other tracking technologies, such as web beacons or local storage objects (LSOs), to perform their services. You can learn more about Google’s privacy practices by reviewing the Google Privacy Policy at <span className="btn-inline" onClick={() => window.open("https://www.google.com/policies/privacy/", "_blank")}>https://www.google.com/policies/privacy/</span>. To opt out of Google Analytics, download the Google Analytics Opt-out Browser Add-on at <span className="btn-inline" onClick={() => window.open("https://tools.google.com/dlpage/gaoptout", "_blank")}>https://tools.google.com/dlpage/gaoptout</span>.</p>
        <p><span className="italic">Cross-Device Use:</span> We and our third-party service providers, including Google, may use the information we collect about you (either directly from our Website, from your device(s), or from a third party) to identify other devices that you use (e.g., a mobile phone, tablet, other computer, etc.). We and our third-party service providers also may use cross-device usage information and other information we learn about you to serve targeted advertising on your devices and to send you emails. To opt-out of cross-device advertising, follow the instructions in the Third-Party Ad Networks section below.</p>
        <p><span className="italic">Do-Not-Track Signals:</span> Please note that our Website does not acknowledge or respond to any potential “Do Not Track” signals transmitted by your browser. If you wish to disable cookies on our Website, do not rely on your browser's “Do Not Track” feature. For more information about do-not-track signals, please refer to your browser’s Help section</p>
        <p><span className="italic">Ad Networks:</span> We use network advertisers to serve ads on non-affiliated websites or other media (e.g., social networking platforms). This allows us and these network advertisers to target ads to you for products and services you might be interested in. Ad network providers, advertisers, sponsors, and/or traffic measurement services may use cookies, JavaScript, pixels, LSOs, and other tracking technologies to measure their ads' effectiveness and to personalize advertising content. These technologies are governed by each entity’s specific privacy policy, not this one. We may provide these advertisers with information, including personal information, about you. Users can opt-out of many ad networks. For instance, you can visit the Digital Advertising Alliance (“DAA”) Consumer Choice Page for information about opting out of interest-based advertising. Please note that opting out of ad networks does not mean you will no longer receive any advertising through our Website or on other websites. You may continue to receive advertisements, for example, based on the particular website that you are viewing (i.e., contextually based ads). More information can be found on the DAA’s websites at www.aboutads.info and <span className="btn-inline" onClick={() => window.open("https://youradchoices.com/appchoices.", "_blank")}>https://youradchoices.com/appchoices.</span></p>

        <p className="p-heading">Securing Your Information</p>
        <p>We use technical and organizational security measures to safeguard your personal information from manipulation, loss, destruction, or unauthorized access, use, disclosure, or alteration. Please note that no data security measures can offer absolute security despite our best efforts. We encourage you to take precautions to protect against unauthorized access to your password, phone, and computer. This can be achieved by signing off after using a shared computer, selecting a robust, unique password that is not easily guessable, and keeping your login details confidential. We do not bear responsibility for any lost, stolen, or compromised passwords or for any unauthorized activity on your account.</p>

        <p className="p-heading">Children's Privacy Notice</p>
        <p>Our Website and Services are not designed for children and we have no intention of collecting personally identifiable information (such as name, address, telephone number) from children. If you find that your child has shared their personal information with us, we request a parent or guardian of the child to reach us at <span className="btn-inline" onClick={() => window.location.href = `mailto:${COMPANY_DATA_PRIVACY_EMAIL}`}>{COMPANY_DATA_PRIVACY_EMAIL}</span> or fill out the form available at: <span className="btn-inline" onClick={() => navigate(PRIVACY_OPT_OUT_URL)}>{COMPANY_OPT_OUT_LINK}</span> to have this information removed from our records.</p>

        <p className="p-heading">California’s “Shine the Light” Law</p>
        <p>California's "Shine the Light" law (Civil Code Section §1798.83) provides certain rights to California residents that have an established business relationship with us with regard to the disclosure of certain types of personal information to third parties for their direct marketing purposes. In order to facilitate these rights, we do not disclose personal information of California residents to third parties for their direct marketing purposes, as defined under California’s “Shine the Light” law, where the resident opts out of such sharing. To opt-out of having your personal information disclosed to third parties for their direct marketing purposes under California’s “Shine the Light” law, please fill out the following Right to Opt Out form at: <span className="btn-inline" onClick={() => navigate(PRIVACY_OPT_OUT_URL)}>{COMPANY_OPT_OUT_LINK}</span>, filling in your first name, last name, and email address, which we need in order to process your request and safeguard your data.</p>
        <p>PLEASE NOTE: To opt-out of all disclosures to third parties for direct marketing purposes, you must provide your personal information to be added to our suppression list.</p>

        <p className="p-heading">Residents of Nevada</p>
        <p>Nevada Revised Statutes Chapter 603A allows a Nevada resident to opt out of future sales of certain information that a website operator has or will collect about the resident. To exercise this option, please contact us at <span className="btn-inline" onClick={() => window.location.href = `mailto:${COMPANY_DATA_PRIVACY_EMAIL}`}>{COMPANY_DATA_PRIVACY_EMAIL}</span> with “Nevada Opt-Out” in the subject line, or fill out the form available at: <span className="btn-inline" onClick={() => navigate(PRIVACY_OPT_OUT_URL)}>{COMPANY_OPT_OUT_LINK}</span></p>

        <p className="p-heading">Third-Party Links</p>
        <p>Our Website may contain links to or from other websites, including social media platforms. This Privacy Statement only pertains to our collection and usage of your data on our Website, and we are not accountable for the privacy policies and practices of other third-party websites, including any linked sites. We advise you to directly contact any third parties if you need information about their privacy policies.</p>

        <p className="p-heading">Your Choices</p>
        <p><span className="italic">To opt out of promotional communications:</span> If you no longer wish to receive promotional messages from us, you can opt out by filling out the following Right to Opt Out form at: <span className="btn-inline" onClick={() => navigate(PRIVACY_OPT_OUT_URL)}>{COMPANY_OPT_OUT_LINK}</span></p>
        <p><span className="italic">To delete your account:</span> Please navigate to <a href="https://getallay.com/#/delete-account">this page</a> to delete your account.</p>
        <p><span className="italic">Changes to This Privacy Statement:</span> This Privacy Statement is effective from the Effective Date stated at the top of this Notice. As the internet continuously evolves, occasional modifications to our Privacy Statement may be necessary, and we reserve the right to make such changes. Any modifications will be promptly communicated on this page. If you have any concerns, questions, or suggestions, please contact the website administrator.</p>
        <p><span className="italic">Contact Us:</span> If you would like to know what information we have about you in our records, ask a question about the information in your file, or request a change or correction to the personal information under our control, please reach out to:</p>
        <div>
          <p>{COMPANY_NAME} Inc.</p>
          <p>{COMPANY_ADDRESS_STREET}</p>
          <p>{COMPANY_ADDRESS_REGION}</p>
          <p className="btn-inline" onClick={() => window.location.href = `mailto:${COMPANY_DATA_PRIVACY_EMAIL}`}>{COMPANY_DATA_PRIVACY_EMAIL}</p>
        </div>
      </div>
    </div>
  )
}

export function OptOutPage() {
  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container relative rounded-t-3xl bg-brand-primary-regular py-16 md:py-20">
          <h1 className="text-3xl text-content-regular pb-4 flex items-center">Privacy Opt out</h1>
          <div className="space-y-4">
            <p>This page should have a drop down to ask user if they want to remove account and all associated data or opt out of marketing purposes.</p>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  )
}