import React from 'react'
import { useLocation } from 'react-router';
import { useNavigate } from "react-router-dom";
import currencyFormatter from 'currency-formatter';
import { HiDownload } from 'react-icons/hi';
import { SimpleLogoHeader, DefaultFooter } from './common/HeaderAndFooter';
import { DOWNLOAD_APP_URL } from './common/Navigation';


export default function OrderDetailPage(props) {
  const { state } = useLocation();
  let navigate = useNavigate();

  return (
    <div className="bg-brand-tertiary-darker">
      <SimpleLogoHeader />
      <div className="app-container">
        <div className="section-container relative rounded-t-3xl bg-brand-primary-regular py-16">
          <div className="max-w-lg space-y-4 mx-auto">
            <h1 className="text-3xl text-content-regular pb-4">Order placed</h1>
            <p>Thank you for place an order with Allay!</p>
            <p>An account linked to <span className="font-semibold">{state.orderRequest.email}</span> has been successfully created! 
            Please download Allay app for personal intake guidance, symptom tracking, order management, and more.</p>
            <div className="inline-block">
              <button className="btn-outline-rounded font-medium mb-4 mr-4" onClick={() => navigate(DOWNLOAD_APP_URL)}><span className="flex"><HiDownload className="mr-2 text-brand-tertiary-regular" size="22" />Download Allay app</span></button>
            </div>
            <div className="py-10 space-y-6 md:border md:border-content-lighter md:rounded-xl md:px-6">
              <div className="space-y-4 pb-6 border-b border-content-lighter">
                <div className="flex">
                  <p className="text-xl flex-grow">Order number</p>
                  <p className="text-xl">{state.transaction.gateway_transaction_id}</p>
                </div>
                <div className="flex">
                  <p className="flex-grow">Product</p>
                  <p>{state.transaction.product.product_name}</p>
                </div>
                <div className="flex">
                  <p className="flex-grow">Price</p>
                  <p>${currencyFormatter.format(state.transaction.product.price, 'USD')}</p>
                </div>
                { state.transaction.discount_amount > 0 &&
                  <div className='space-y-6'>
                    <div className="flex flex-row pb-6 border-b border-content-lighter">
                      <p className="flex-grow">Referral discount</p>
                      <p className='text-content-error'>-${currencyFormatter.format(state.transaction.discount_amount, 'USD')}</p>
                    </div>
                    <div className="flex flex-row">
                      <p className="flex-grow">Subtotal</p>
                      <p className>${currencyFormatter.format(state.transaction.subtotal, 'USD')}</p>
                    </div>
                  </div>
                }
                <div className="flex flex-row">
                  <p className="flex-grow">Sales Tax ({state.transaction.tax_rate * 100}%)</p>
                  <p>${currencyFormatter.format(state.transaction.tax_amount, 'USD')}</p>
                </div>
                <div className="flex">
                  <p className="flex-grow font-bold">Order total</p>
                  <p className="font-bold">${currencyFormatter.format(state.transaction.total, 'USD')}</p>
                </div>
              </div>
              {state.transaction.shipment &&
                <div className="space-y-2">
                  <p className="flex-grow font-bold">USPS tracking number</p>
                  <a className="underline" href={state.transaction.shipment.tracking_link} target='_blank' rel="noreferrer">{state.transaction.shipment.tracking_id}</a>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  )
}