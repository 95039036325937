import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router';
import currencyFormatter from 'currency-formatter'
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import InputField, { InputCheckBox } from "./common/InputField";
import { NavigateBack, ORDER_DETAIL_URL, PRIVACY_URL, TERMS_URL } from './common/Navigation';
import { SimpleLogoHeader, DefaultFooter } from './common/HeaderAndFooter';
import API from "../api";
import { GetResponseErrorMessage } from "./common/Util";

export const PAYMENT_PLAN_MONTHLY_NAME = "MONTHLY";
export const PAYMENT_PLAN_MONTHLY_PRICE = 60;

export default function CheckoutPage() {
  const { state } = useLocation();
  let navigate = useNavigate();

  let emailRef = useRef(null);
  let shippingRef = useRef(null);
  let billingRef = useRef(null);

  const EMAIL_ERROR_MSG = "Please enter a valid email address";
  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState('');

  const SHIPPING_ERROR_MSG = "Please enter a valid shipping address";
  const [shippingError, setShippingError] = useState('');
  const [shippingFirstName, setShippingFirstName] = useState('');
  const [shippingLastName, setShippingLastName] = useState('');
  const [shippingStreet1, setShippingStreet1] = useState('');
  const [shippingStreet2, setShippingStreet2] = useState('');
  const [shippingCity, setShippingCity] = useState('');
  const [shippingRegion, setShippingRegion] = useState('');
  const [shippingPostalCode, setShippingPostalCode] = useState('');

  const BILLING_ERROR_MSG = "Please enter a valid payment method";
  const [billingError, setBillingError] = useState('');
  const [shouldUseShippingAddressAsBillingAddress, setShouldUseShippingAddressAsBillingAddress] = useState(true);
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpirationDate, setCardExpirationDate] = useState('');
  const [cardSecurityCode, setCardSecurityCode] = useState('');
  const [billingFirstName, setBillingFirstName] = useState('');
  const [billingLastName, setBillingLastName] = useState('');
  const [billingStreet1, setBillingStreet1] = useState('');
  const [billingStreet2, setBillingStreet2] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingRegion, setBillingRegion] = useState('');
  const [billingPostalCode, setBillingPostalCode] = useState('');

  const [checkoutError, setCheckoutError] = useState('');
  const [ctaText, setCtaText] = useState('Place order');
  const [disableCta, setDisableCta] = useState(false);

  const [tax, setTax] = useState(null);
  const [referralCode, setReferralCode] = useState('');
  const [referralCodeText, setReferralCodeText] = useState('');

  useEffect(() => {
    function recalculateTax() {
      setTax(null);
      const postalCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
      if (shippingRegion.length > 1 && postalCodeRegex.test(shippingPostalCode)) {
        API.calculateSaleTax({
          "product_id": state.product.id,
          "referral_code": referralCode.toLowerCase(),
          "shipping_region": shippingRegion,
          "shipping_postal_code": shippingPostalCode,
        }).then(response => {
          if (response.ok) {
            response.json().then(taxInfo => {
              setTax(taxInfo.data);
            });
          }
        });
      }
    }
    recalculateTax();
  }, [shippingRegion, shippingPostalCode, referralCode, state.product.id]);

  function handleCardNumberChange(event) {
    var ccNumber = event.target.value.replace(/[^\d]/g, "");
    ccNumber = ccNumber.substring(0, Math.min(16, ccNumber.length));

    var parts = [];
    for (var i = 0; i < ccNumber.length; i += 4) {
      parts.push(ccNumber.substring(i, i + 4));
    }
    if (parts.length) {
      ccNumber = parts.join(' ');
    }

    setCardNumber(ccNumber);
  }

  function handleCardExpirationDateChange(event) {
    var expiration = event.target.value.replace(/[^\d]/g, "");
    expiration = expiration.substring(0, Math.min(6, expiration.length));

    if (expiration.length > 2) {
      setCardExpirationDate(expiration.substring(0, 2) + '/' + expiration.substring(2));
    } else {
      setCardExpirationDate(expiration);
    }
  }

  function processOrderPlacement() {
    // Reset all errors
    setEmailError("");
    setShippingError("");
    setBillingError("");
    setCheckoutError("");

    // Validate order information and place an order.
    let expirationMonthAndYear = cardExpirationDate.split("/");
    if (validateOrder()) {
      const orderRequestParams = {
        "email": email,
        "product_id": state.product.id,
        "referral_code": '',
        "shipping_first_name": shippingFirstName,
        "shipping_last_name": shippingLastName,
        "shipping_street1": shippingStreet1,
        "shipping_street2": shippingStreet2,
        "shipping_city": shippingCity,
        "shipping_region": shippingRegion,
        "shipping_postal_code": shippingPostalCode,
        "card_number": cardNumber.replace(/[^\d]/g, ""),
        "card_expiration_month": expirationMonthAndYear[0],
        "card_expiration_year": expirationMonthAndYear[1],
        "card_security_code": cardSecurityCode,
        "billing_first_name": shouldUseShippingAddressAsBillingAddress ? shippingFirstName : billingFirstName,
        "billing_last_name": shouldUseShippingAddressAsBillingAddress ? shippingLastName : billingLastName,
        "billing_street1": shouldUseShippingAddressAsBillingAddress ? shippingStreet1 : billingStreet1,
        "billing_street2": shouldUseShippingAddressAsBillingAddress ? shippingStreet2 : billingStreet2,
        "billing_city": shouldUseShippingAddressAsBillingAddress ? shippingCity : billingCity,
        "billing_region": shouldUseShippingAddressAsBillingAddress ? shippingRegion : billingRegion,
        "billing_postal_code": shouldUseShippingAddressAsBillingAddress ? shippingPostalCode : billingPostalCode
      };

      if (tax !== null && tax.discount > 0 && referralCode.length > 0) {
        orderRequestParams['referral_code'] = referralCode.toLowerCase();
      }

      setDisableCta(true);
      setCtaText("Processing order ...");

      API.placeAnOrder(orderRequestParams)
        .then(response => {
          if (response.ok) {
            response.json().then(transactionDetail => {
              navigate(ORDER_DETAIL_URL, {
                state: {
                  transaction: transactionDetail.data,
                  orderRequest: orderRequestParams
                }
              });
            });
          } else {
            throw response;
          }
        }).catch(e => {
          setDisableCta(false);
          e.json().then(err => setCheckoutError(GetResponseErrorMessage(err)));
          setCtaText("Place order");
          console.log(e);
        });
    }
  }

  function validateOrder() {
    if (validateEmail()) {
      setEmailError("");
    } else {
      emailRef.current.scrollIntoView({ behavior: 'smooth' });
      setEmailError(EMAIL_ERROR_MSG);
      return false;
    }

    if (validateShippingAddress()) {
      setShippingError("");
    } else {
      shippingRef.current.scrollIntoView({ behavior: 'smooth' });
      setShippingError(SHIPPING_ERROR_MSG);
      return false;
    }

    if (validateBilling()) {
      setBillingError("");
    } else {
      billingRef.current.scrollIntoView({ behavior: 'smooth' });
      setBillingError(BILLING_ERROR_MSG);
      return false;
    }

    return true;
  }

  function validateEmail() {
    // Check if the email address has the correct format
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  function validateShippingAddress() {
    const postalCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
    return shippingFirstName.length > 0 && shippingLastName.length > 0 && shippingStreet1.length > 4 && shippingCity.length > 2 && shippingRegion.length > 1 && postalCodeRegex.test(shippingPostalCode);
  }

  function validateBilling() {
    // Check if the credit card number has the correct format
    const regex = /^[0-9]{16}$/;
    if (!regex.test(cardNumber.replace(/[^\d]/g, ""))) {
      return false;
    }

    // Check if the expiration date is valid
    let expirationMonthAndYear = cardExpirationDate.split("/");
    if (expirationMonthAndYear.length !== 2) {
      return false;
    }
    var date = new Date();
    date.setFullYear(expirationMonthAndYear[1], expirationMonthAndYear[0], 1);
    if (date.getTime() < new Date().getTime()) {
      return false;
    }

    return validateBillingAddress;
  }

  function validateBillingAddress() {
    if (!shouldUseShippingAddressAsBillingAddress) {
      const postalCostRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
      return billingFirstName.length > 0 && billingLastName.length > 0 && billingStreet1.length > 4 && billingCity.length > 2 && billingRegion.length > 1 && postalCostRegex.test(billingPostalCode);
    }
    return true;
  }

  return (
    <div className="bg-brand-tertiary-darker">
      <SimpleLogoHeader />
      <div className="app-container">
        <div className="section-container relative rounded-t-3xl bg-brand-primary-regular py-14 overflow-hidden lg:overflow-visible">
          <div>
            <IoArrowBackCircleOutline className="p-2 cursor-pointer -ml-2 lg:hidden" size="40" onClick={() => NavigateBack(navigate)} />
            <h1 className="text-3xl text-content-regular pb-4 flex items-center"><IoArrowBackCircleOutline className="p-2 cursor-pointer -ml-2 hidden lg:inline-block lg:mr-2 lg:-ml-12" size="40" onClick={() => NavigateBack(navigate)} />Checkout</h1>
          </div>
          <div className="grid grid-cols-12 lg:gap-8 xl:gap-16">
            <div className="col-span-12 space-y-12 pr-0 lg:col-span-7">
              <div className="space-y-4" ref={emailRef}>
                <h4> Account info</h4>
                {emailError.length > 0 &&
                  <p className="text-content-error">{emailError}</p>
                }
                <InputField
                  label="Email address"
                  name="email"
                  type="email"
                  autocomplete="email"
                  x_autocompletetype="email"
                  attention={emailError.length > 0}
                  onChange={(event) => setEmail(event.target.value)} />
                <p className="font-content text-sm text-content-light">You will use this email address to manage your Allay usage</p>
              </div>
              <div className="space-y-4" ref={shippingRef}>
                <h4 className="pb-2">Shipping Address</h4>
                {shippingError.length > 0 &&
                  <p className="text-content-error">{shippingError}</p>
                }
                <div className="flex flex-row space-x-4">
                  <InputField
                    className="flex-grow"
                    label="First name"
                    name="shippingFirstName"
                    type="text"
                    autocomplete="given-name"
                    x_autocompletetype="given-name"
                    attention={shippingError.length > 0}
                    onChange={(event) => setShippingFirstName(event.target.value)} />
                  <InputField
                    className="flex-grow"
                    label="Last name"
                    name="shippingLastName"
                    type="text"
                    autocomplete="family-name"
                    x_autocompletetype="family-name"
                    attention={shippingError.length > 0}
                    onChange={(event) => setShippingLastName(event.target.value)} />
                </div>
                <div className="flex flex-col space-y-4 space-x-0 lg:flex-row lg:space-y-0 lg:space-x-4">
                  <InputField
                    className="flex-grow"
                    label="Address"
                    name="shippingAddress1"
                    type="text"
                    autocomplete="address-line1"
                    x_autocompletetype="address-line1"
                    attention={shippingError.length > 0}
                    onChange={(event) => setShippingStreet1(event.target.value)} />
                  <InputField
                    className="max-w-full lg:max-w-35pc"
                    label="Apt, suite (opt)"
                    name="shippingAddress2"
                    type="text"
                    autocomplete="address-line2"
                    x_autocompletetype="address-line2"
                    attention={shippingError.length > 0}
                    onChange={(event) => setShippingStreet2(event.target.value)} />
                </div>
                <div className="flex flex-col space-y-4 space-x-0 lg:flex-row lg:space-y-0 lg:space-x-4">
                  <InputField
                    className="w-full lg:w-6/12"
                    label="City"
                    name="shippingCity"
                    type="text"
                    autocomplete="locality"
                    x_autocompletetype="locality"
                    attention={shippingError.length > 0}
                    onChange={(event) => setShippingCity(event.target.value)} />
                  <div className="flex flex-row space-x-4">
                    <InputField
                      className="flex-grow"
                      label="State"
                      name="shippingRegion"
                      type="text"
                      autocomplete="region"
                      x_autocompletetype="region"
                      attention={shippingError.length > 0}
                      onChange={(event) => setShippingRegion(event.target.value)} />
                    <InputField
                      className="max-w-35pc"
                      label="Zip code"
                      name="shippingPostalCode"
                      type="text"
                      autocomplete="postal-code"
                      x_autocompletetype="postal-code"
                      attention={shippingError.length > 0}
                      onChange={(event) => setShippingPostalCode(event.target.value)} />
                  </div>
                </div>
              </div>
              <div className="space-y-4" ref={billingRef}>
                <div className="flex flex-col pb-2 align-middle md:flex-row">
                  <h4 className="flex-grow">Payment Info</h4>
                  <img className="object-contain h-7 w-52" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1687247672/allay/payment-options.png" alt="Different payments options allowed including: visa, mastercard, american express, discover" />
                </div>
                {billingError.length > 0 &&
                  <p className="text-content-error">{billingError}</p>
                }
                <InputField
                  label="Card number"
                  value={cardNumber}
                  name="cardnumber"
                  type="tel"
                  autocomplete="cc-number"
                  x_autocompletetype="cc-number"
                  attention={billingError.length > 0}
                  onChange={handleCardNumberChange} />
                <div className="flex space-x-4">
                  <InputField
                    className="flex-grow"
                    label="Expiration (MM/YYYY)"
                    value={cardExpirationDate}
                    name="cardexpiration"
                    type="tel"
                    autocomplete="cc-exp"
                    x_autocompletetype="cc-exp"
                    attention={billingError.length > 0}
                    onChange={handleCardExpirationDateChange} />
                  <InputField
                    className="max-w-35pc"
                    label="CVC"
                    value={cardSecurityCode}
                    name="cardcvc"
                    type="number"
                    autocomplete="cc-csc"
                    x_autocompletetype="cc-csc"
                    attention={billingError.length > 0}
                    onChange={(event) => setCardSecurityCode(event.target.value)} />
                </div>
                {!shouldUseShippingAddressAsBillingAddress &&
                  <form className="pb-2 space-y-4">
                    <div className="flex flex-row space-x-4">
                      <InputField
                        className="flex-grow"
                        label="First name"
                        name="billingFirstName"
                        type="text"
                        autocomplete="given-name"
                        x_autocompletetype="given-name"
                        attention={shippingError.length > 0}
                        onChange={(event) => setBillingFirstName(event.target.value)} />
                      <InputField
                        className="flex-grow"
                        label="Last name"
                        name="billingLastName"
                        type="text"
                        autocomplete="family-name"
                        x_autocompletetype="family-name"
                        attention={shippingError.length > 0}
                        onChange={(event) => setBillingLastName(event.target.value)} />
                    </div>
                    <div className="flex flex-col space-y-4 space-x-0 lg:flex-row lg:space-y-0 lg:space-x-4">
                      <InputField
                        className="flex-grow"
                        label="Billing Address"
                        name="billingAddress1"
                        type="text"
                        autocomplete="address-line1"
                        x_autocompletetype="address-line1"
                        attention={billingError.length > 0}
                        onChange={(event) => setBillingStreet1(event.target.value)} />
                      <InputField
                        className="max-w-full lg:max-w-35pc"
                        label="Apt, Suite (optional)"
                        name="billingAddress2"
                        type="text"
                        autocomplete="address-line2"
                        x_autocompletetype="address-line2"
                        attention={billingError.length > 0}
                        onChange={(event) => setBillingStreet2(event.target.value)} />
                    </div>
                    <div className="flex flex-col space-y-4 space-x-0 lg:flex-row lg:space-y-0 lg:space-x-4">
                      <InputField
                        className="w-full lg:w-6/12"
                        label="City"
                        name="billingCity"
                        type="text"
                        attention={billingError.length > 0}
                        onChange={(event) => setBillingCity(event.target.value)} />
                      <div className="flex flex-row space-x-4">
                        <InputField
                          className="flex-grow"
                          label="State"
                          name="billingRegion"
                          type="text"
                          autocomplete="region"
                          x_autocompletetype="region"
                          attention={billingError.length > 0}
                          onChange={(event) => setBillingRegion(event.target.value)} />
                        <InputField
                          className="max-w-35pc"
                          label="Zip code"
                          name="billingPostalCode"
                          type="text"
                          autocomplete="postal-code"
                          x_autocompletetype="postal-code"
                          attention={billingError.length > 0}
                          onChange={(event) => setBillingPostalCode(event.target.value)} />
                      </div>
                    </div>
                  </form>
                }
                <InputCheckBox
                  className="pb-12"
                  name="useshippingaddress"
                  labelClassName="font-medium"
                  checked={true}
                  onToggle={(toggleState) => setShouldUseShippingAddressAsBillingAddress(toggleState)}>Billing address is the same as above</InputCheckBox>
              </div>
            </div>
            <div className="col-span-12 pb-12 lg:col-span-5">
              <div className="space-y-6 lg:rounded-xl lg:p-6 lg:border lg:border-content-lighter">
                <h4 className="pb-2">Order details</h4>
                <div className="space-y-4 pb-6 border-b border-content-lighter">
                  <div className="flex flex-row">
                    <p className="flex-grow">{state.product.product_name}</p>
                    <p>${currencyFormatter.format(parseFloat(state.product.price), 'USD')}</p>
                  </div>
                  {tax !== null && tax.discount > 0 &&
                    <div className="space-y-6">
                      <div className="flex flex-row pb-6 border-b border-content-lighter">
                        <p className="flex-grow">Referral discount</p>
                        <p className="text-content-error">-${currencyFormatter.format(tax.discount, 'USD')}</p>
                      </div>
                      <div className="flex flex-row">
                        <p className="flex-grow">Subtotal</p>
                        <p>${currencyFormatter.format(parseFloat(state.product.price) - tax.discount, 'USD')}</p>
                      </div>
                    </div>
                  }
                  <div className="flex flex-row">
                    <p className="flex-grow">Sales Tax {tax !== null && tax.rate !== 0 && `(${tax.rate * 100}%)`}</p>
                    {tax === null 
                      ? <p className="text-content-light">--</p>
                      : <p>${currencyFormatter.format(tax.amount, 'USD')}</p>
                    }
                  </div>
                </div>
                <div>
                  <div className="flex flex-row">
                    <p className="font-bold flex-grow">Order Total</p>
                    { tax === null ?
                      <p className="font-bold">${currencyFormatter.format(state.product.price, 'USD')} (+tax)</p>
                      : <p className="font-bold">${currencyFormatter.format(parseFloat(state.product.price) - tax.discount + tax.amount, 'USD')}</p>
                    }
                  </div>
                  <p className="text-right text-sm font-medium">Free shipping on every order!</p>
                </div>
                {tax !== null && tax.discount === 0 &&
                  <div>
                    { referralCode.length > 0 && <p className="text-content-error">Invalid referral code</p>}
                    <div className="flex flex-row">
                      <InputField
                        className="flex-grow mr-2"
                        label="Have a referral code?"
                        name="promoCode"
                        type="text"
                        value={referralCodeText.toUpperCase()}
                        onChange={(event) => setReferralCodeText(event.target.value)} />
                      <button className="btn-outline" onClick={() => setReferralCode(referralCodeText)}>Apply</button>
                    </div>
                  </div>
                }
                <div className="space-y-2 pt-4">
                  {checkoutError.length > 0 &&
                    <p className="text-content-error">{checkoutError}</p>
                  }
                  <button className="btn-primary w-full" disabled={disableCta} onClick={processOrderPlacement}>{ctaText}</button>
                  <p className="text-sm text-center pb-2">By clicking “Place order”, I agree to Allay’s <span className="underline cursor-pointer" onClick={() => navigate(PRIVACY_URL)}>Privacy Policy</span> and <span className="underline cursor-pointer" onClick={() => navigate(TERMS_URL)}>Terms of Use</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  )
}