import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HOME_URL, LEARN_MORE_URL, PRIVACY_URL, SOCIAL_MEDIA_FACEBOOK, SOCIAL_MEDIA_INSTAGRAM, SOCIAL_MEDIA_TWITTER, TERMS_URL, PRODUCT_URL, FAQ_URL, DOWNLOAD_APP_URL, APPLE_STORE_APP_LINK, GOOGLE_PLAY_STORE_LINK, OVERVIEW_URL, MILESTONES_URL } from "./Navigation";
import { Logo } from "./Logo";
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';


export function PromotionHeader() {
  // return (
  //   <div className="bg-brand-tertiary-light uppercase text-content-regular font-bold py-2 text-center">
  //     20% off your entire subscription when pay annually
  //   </div>
  // )
}

export function SimpleLogoHeader() {
  let navigate = useNavigate();

  return (
    <div className="app-container bg-brand-tertiary-darker py-4 sticky top-0 z-40">
      <div className="py-1 px-2 mx-auto cursor-pointer" onClick={() => navigate(HOME_URL)}>
        <Logo className="flex justify-center text-brand-primary-regular" />
      </div>
    </div>
  )
}

export function PreSignInHeader() {
  let navigate = useNavigate();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  return (
    <nav className="app-container bg-brand-tertiary-darker sticky top-0 py-0 z-40">
      <div className="flex flex-wrap items-center justify-between mx-auto px-4 md:px-8 py-4">
        <div className="cursor-pointer" onClick={() => navigate(HOME_URL)}>
          <Logo className="text-brand-primary-regular" />
        </div>
        <button type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-regular text-brand-primary-regular rounded-lg md:hidden focus:outline-none" onClick={() => setOpenMobileMenu(!openMobileMenu)}>
          <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
        <div className={`${!openMobileMenu && 'hidden'} w-full md:block md:w-auto md:order-1`}>
          <ul className="font-medium flex flex-col  p-4 md:p-0 mt-4 border border-brand-primary-regular rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 ">
            <li>
              <p className="block cursor-pointer py-2 pl-3 pr-4 text-brand-primary-regular rounded md:border-0 md:p-0" onClick={() => navigate(OVERVIEW_URL)}>Our product</p>
            </li>
            <li>
              <p className="block cursor-pointer py-2 pl-3 pr-4 text-brand-primary-regular rounded md:border-0 md:p-0" onClick={() => navigate(LEARN_MORE_URL)}>Clinical research</p>
            </li>
            <li>
              <p className="block cursor-pointer py-2 pl-3 pr-4 text-brand-primary-regular rounded md:border-0 md:p-0" onClick={() => navigate(DOWNLOAD_APP_URL)}>Mobile app</p>
            </li>
            <li>
              <p className="block cursor-pointer py-2 pl-3 pr-4 text-brand-primary-regular rounded md:border-0 md:p-0" onClick={() => navigate(PRODUCT_URL)}>Shop now</p>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export function DefaultFooter() {
  let navigate = useNavigate();

  return (
    <div className="bg-brand-tertiary-darker">
      <div className="app-container py-16">
        <div className="grid grid-cols-3">
          <div className="col-span-2 space-y-2">
            <Logo className="text-brand-primary-regular" />
            <p className="text-brand-primary-regular flex flex-row -ml-3">
              <span className="py-2 px-3 cursor-pointer" onClick={() => window.open(SOCIAL_MEDIA_FACEBOOK, '_blank')}><FaFacebookF size="20" /></span>
              <span className="py-2 px-3 cursor-pointer" onClick={() => window.open(SOCIAL_MEDIA_TWITTER, '_blank')}><FaTwitter size="20" /></span>
              <span className="py-2 px-3 cursor-pointer" onClick={() => window.open(SOCIAL_MEDIA_INSTAGRAM, '_blank')}><FaInstagram size="20" /></span>
            </p>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="flex flex-col pr-12 lg:pr-20">
              <button className="btn-text text-brand-primary-regular text-sm" onClick={() => navigate(FAQ_URL)}>FAQs</button>
              <button className="btn-text text-brand-primary-regular text-sm" onClick={() => navigate(OVERVIEW_URL)}>Product</button>
              <button className="btn-text text-brand-primary-regular text-sm" onClick={() => navigate(LEARN_MORE_URL)}>Research</button>
              <button className="btn-text text-brand-primary-regular text-sm" onClick={() => navigate(MILESTONES_URL)}>Intake guide</button>
            </div>
            <div className="flex flex-col">
              <button className="btn-text text-brand-primary-regular text-sm" onClick={() => navigate(DOWNLOAD_APP_URL)}>Download</button>
              <button className="btn-text text-brand-primary-regular text-sm" onClick={() => navigate(PRODUCT_URL)}>Shop</button>
              <button className="btn-text text-brand-primary-regular text-sm" onClick={() => window.location = "mailto:hello@getallay.com"}>Email us</button>
            </div>
          </div>
          <div className="col-span-3 md:col-span-2 pt-8">
            <div className="pb-4 flex flex-col space-y-2 space-x-0 sm:space-y-0 sm:space-x-2 sm:flex-row">
              <a href={APPLE_STORE_APP_LINK} target="_blank" rel="noreferrer"><img width={180} src="https://res.cloudinary.com/dqafyoayf/image/upload/v1704667597/Apple_gq3yrt.svg" alt="Allay ios app link" /></a>
              <a href={GOOGLE_PLAY_STORE_LINK} target="_blank" rel="noreferrer"><img width={180} src="https://res.cloudinary.com/dqafyoayf/image/upload/v1704667597/Google_slzww4.svg" alt="Allay android app link" /></a>
            </div>
            <div className="space-x-4 flex flex-row items-center">
              <button className="btn-text text-brand-primary-regular text-xs" onClick={() => navigate(TERMS_URL)}>Terms & conditions</button>
              <button className="btn-text text-brand-primary-regular text-xs" onClick={() => navigate(PRIVACY_URL)}>Privacy policy</button>
              <p className="text-brand-primary-regular text-xs hidden sm:inline">© 2023 Allay. All rights reserved.</p>
            </div>
          </div>
          <p className="col-span-3 text-brand-primary-regular text-xs sm:hidden">© 2023 Allay. All rights reserved.</p>
          <div className="col-span-3 md:col-span-1 mt-8 p-4 border border-white border-opacity-10">
            <p className="text-brand-primary-regular text-xs">* These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease.</p>
          </div>
        </div>
      </div>
    </div>
  )
}