import React from 'react'
import { useNavigate } from "react-router-dom";
import { PromotionHeader, PreSignInHeader, DefaultFooter } from './common/HeaderAndFooter';
import { RESEARCH_OIT_URL } from './common/Navigation';

export default function LearnMorePage() {
  let navigate = useNavigate();

  return (
    <div className="bg-brand-tertiary-darker">
      <PromotionHeader />
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container rounded-t-3xl overflow-hidden bg-brand-primary-regular py-16 md:py-20">
          <h1 className="text-4xl text-center">Research library</h1>
          <div className="max-w-2xl py-16 mx-auto space-y-12">
            <div className="max-w-md">
              <h2 className="text-2xl font-light">The major themes that have emerged from these clinical trials include a consistent demonstration that peanut OIT raises the threshold of reaction and in certain patients can achieve sustained unresponsiveness.</h2>
              <p className="pt-4"><a className="underline font-semibold" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7223701/" target="_blank" rel="noreferrer">Peanut Oral Immunotherapy: a Current Perspective</a></p>
              <p className="text-content-light font-sm">2020 Apr 20</p>
            </div>

            <div className="max-w-md ml-auto">
              <h2 className="text-2xl font-light">Low-dose OIT induces immunological changes and has the capability of achieving sustained unresponsiveness in children with peanut anaphylaxis.</h2>
              <p className="pt-4"><a className="underline font-semibold" href="https://pubmed.ncbi.nlm.nih.gov/29603410/" target="_blank" rel="noreferrer">Low-dose oral immunotherapy for children with anaphylactic peanut allergy in Japan</a></p>
              <p className="text-content-light font-sm">2018 May 10</p>
            </div>

            <div className="max-w-md bg-brand-primary-light rounded-2xl py-8 px-4 md:px-8 text-center space-y-6 mx-auto">
              <p>The following scientific research is for informational use only. Allay provides this information as a service and does not receive compensation for studies referenced. This information should not be read to recommend or endorse any specific products. Dietary supplements are not intended to diagnose, treat, cure, or prevent any disease.</p>
              <button className="btn-primary" onClick={() => navigate(RESEARCH_OIT_URL)}>Continue</button>
            </div>
          </div>          
        </div>

        <div className="overflow-hidden bg-brand-primary-regular">
          <img className="object-cover w-full min-h-[200px]" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1698087055/allay/about_hnypy7.jpg" alt="Learn more about allay" />
            <div className="section-container text-left content-center pt-12 pb-20">
              <h1 className="text-content-regular max-w-[24ch] pb-4 sm:mx-auto text-2xl sm:text-center md:text-3xl lg:text-4xl">
                The science behind <span className="text-brand-tertiary-regular">Allay</span>
              </h1>
              <div className="max-w-xl mx-auto space-y-6">
                <p className="text-content-regular text-lg">Allay brings the research-backed approach of oral immunotherapy to your daily routine. While we don't claim to treat or cure peanut allergies, our product is designed with an understanding of the principles behind oral immunotherapy.</p>
                <p className="text-content-regular text-lg">Numerous scientific studies indicate potential benefits of oral immunotherapy for some individuals. This method, under medical supervision, involves the intake of small, controlled amounts of an allergen, such as peanuts, to potentially influence the body's immune response over time.</p>
                <p className="text-content-regular text-lg">Notable research, like <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7527748/" target='_blank' rel="noreferrer" className='btn-inline'>this study</a> funded by the National Institutes of Health (NIH), discusses the remission of peanut allergies in a subset of young children using oral immunotherapy.</p>
                <p className="text-content-regular text-lg">Additionally, the U.S. Food and Drug Administration (FDA) has approved an oral immunotherapy treatment for peanut allergies called Palforzia. This marks a significant milestone in the recognition of oral immunotherapy's potential.</p>
                <p className="text-content-regular text-lg">With Allay, each clear capsule is composed of two ingredients: organic peanut powder and heat-treated rice powder. Our product is carefully produced in a cGMP-certified facility, emphasizing our commitment to consistent manufacturing and your wellness.</p>
                <p className="text-content-regular text-lg">Please note, Allay should be used as part of a balanced diet and is not intended as a treatment or prevention for peanut allergies or any disease.</p>
              </div>
            </div>
          </div>
        </div>
      <DefaultFooter />
    </div>
  )
}