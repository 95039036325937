import React, { useRef, useEffect} from 'react'
import { useLocation } from 'react-router';
import { PreSignInHeader, DefaultFooter } from './common/HeaderAndFooter';
import { useNavigate } from "react-router-dom";
import { DOWNLOAD_APP_URL } from './common/Navigation';
import { HiDownload } from 'react-icons/hi';


export default function MilestonesPage() {
  const { state } = useLocation();
  const adaptAnchor = useRef(null);
  const buildAnchor = useRef(null);
  const maintainAnchor = useRef(null);
  let navigate = useNavigate();

  useEffect(() => {
    if (state?.anchor === 'adapt') {
      adaptAnchor.current.style.scrollMargin = "80px";
      adaptAnchor.current.scrollIntoView({ behavior: "smooth" });
    }
    if (state?.anchor === 'build') {
      buildAnchor.current.style.scrollMargin = "80px";
      buildAnchor.current.scrollIntoView({ behavior: "smooth" });
    }
    if (state?.anchor === 'maintain') {
      maintainAnchor.current.style.scrollMargin = "80px";
      maintainAnchor.current.scrollIntoView({ behavior: "smooth" });
    }
  })

  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container relative rounded-t-3xl bg-brand-primary-regular min-h-[50vh] py-16 md:py-20">
          <div className="max-w-xl mx-auto space-y-12">
            <div className="space-y-4">
              <h1 className="text-4xl text-content-regular pb-4">The Allay journey</h1>
              <p>The Allay journey unfolds in three main phases: Adapt, Build, and Maintain</p>
              <p>It's important to remember that your toddler's consumption schedule, feedback, and progression to the next phase will be guided by our user-friendly app. We highly encourage you to regularly log your child's intake and feedback to allow us to advise you on when it's optimal to transition to a higher dosage.</p>
              <button className="btn-outline-rounded font-medium mb-4 mr-4" onClick={() => navigate(DOWNLOAD_APP_URL)}><span className="flex"><HiDownload className="mr-2 text-brand-tertiary-regular" size="22" />Download Allay app</span></button>
            </div>
            <div ref={adaptAnchor}>
              <MilestonesPhase1 />
            </div>
            <div ref={buildAnchor}>
              <MilestonesPhase2 />
            </div>
            <div ref={maintainAnchor}>
              <MilestonesPhase3 />
            </div>
            <p>Remember, your children's safety and progress are our top priorities at Allay. Enjoy this journey to enhanced immunity at your own pace, guided by our carefully designed stages.</p>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  )
}

function MilestonesPhase1() {
  return (
    <div className="bg-brand-primary-light rounded-2xl w-full py-8 px-4 md:px-8 text-left space-y-6">
      <h2 className="text-2xl">Phase 1: Adapt</h2>
      <div>
        <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Objective</p>
        <p>We begin with a safe, minimal dose of 2mg, which <a className="underline font-semibold" href="https://www.allergicliving.com/2022/09/23/test-shows-how-much-peanut-an-allergic-child-can-tolerate/" target="_blank" rel="noreferrer">studies</a> show is well tolerated by 92% of individuals with severe peanut allergies. Nonetheless, please ensure you have your usual safety measures in place.</p>
      </div>
      <div>
        <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Product</p>
        <p>2mg organic peanut capsule</p>
      </div>
      <div className="space-y-4">
        <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Sample regimen</p>
        <table className="w-full rounded-t-2xl overflow-hidden">
          <thead>
            <tr className="border border-content-lighter bg-content-lighter">
              <th>Weeks</th>
              <th>Quantity</th>
              <th>Peanuts</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border border-content-lighter">
              <td>1st - 3rd</td>
              <td>1 capsule</td>
              <td>2 mg</td>
            </tr>
            <tr className="border border-content-lighter">
              <td>4th - 6th</td>
              <td>2 capsules</td>
              <td>4 mg</td>
            </tr>
            <tr className="border border-content-lighter">
              <td>7th - 9th</td>
              <td>3 capsules</td>
              <td>6 mg</td>
            </tr>
            <tr className="border border-content-lighter">
              <td>10th -12th</td>
              <td>4 capsules</td>
              <td>8 mg</td>
            </tr>
            <tr className="border border-content-lighter">
              <td>13th - 15th</td>
              <td>6 capsules</td>
              <td>12 mg</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

function MilestonesPhase2() {
  return (
    <div className="bg-brand-primary-light rounded-2xl w-full py-8 px-4 md:px-8 text-left space-y-6">
      <h2 className="text-2xl">Phase 2: Build</h2>
      <div>
        <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Objective</p>
        <p>Next, we work to desensitize their body to peanuts by gradually increase their peanut flour intakes up to 768mg. This process is split into 3 stages</p>
      </div>
      <div className="space-y-2 border-t border-content-lighter py-8 space-y-6">
        <h3 className="text-xl underline">Stage 1</h3>
        <div>
          <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Product</p>
          <p>8mg organic peanut capsule</p>
        </div>
        <div className="space-y-4">
          <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Sample regimen</p>
          <table className="w-full rounded-t-2xl overflow-hidden">
            <thead>
              <tr className="border border-content-lighter bg-content-lighter">
                <th>Weeks</th>
                <th>Quantity</th>
                <th>Peanuts</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border border-content-lighter">
                <td>16th - 18th</td>
                <td>2 capsules</td>
                <td>16 mg</td>
              </tr>
              <tr className="border border-content-lighter">
                <td>19th - 21st</td>
                <td>3 capsules</td>
                <td>24 mg</td>
              </tr>
              <tr className="border border-content-lighter">
                <td>22nd - 24th</td>
                <td>4 capsules</td>
                <td>32 mg</td>
              </tr>
              <tr className="border border-content-lighter">
                <td>25th - 27th</td>
                <td>6 capsules</td>
                <td>48 mg</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="space-y-2 border-t border-content-lighter py-8 space-y-6">
        <h3 className="text-xl underline">Stage 2</h3>
        <div>
          <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Product</p>
          <p>32mg organic peanut capsule</p>
        </div>
        <div className="space-y-4">
          <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Sample regimen</p>
          <table className="w-full rounded-t-2xl overflow-hidden">
            <thead>
              <tr className="border border-content-lighter bg-content-lighter">
                <th>Weeks</th>
                <th>Quantity</th>
                <th>Peanuts</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border border-content-lighter">
                <td>28th - 30th</td>
                <td>2 capsules</td>
                <td>64 mg</td>
              </tr>
              <tr className="border border-content-lighter">
                <td>31st - 33th</td>
                <td>3 capsules</td>
                <td>96 mg</td>
              </tr>
              <tr className="border border-content-lighter">
                <td>34th - 36th</td>
                <td>4 capsules</td>
                <td>128 mg</td>
              </tr>
              <tr className="border border-content-lighter">
                <td>37th - 39th</td>
                <td>6 capsules</td>
                <td>192 mg</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="space-y-2 border-t border-content-lighter py-8 space-y-6">
        <h3 className="text-xl underline">Stage 3</h3>
        <div>
          <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Product</p>
          <p>128mg organic peanut capsule</p>
        </div>
        <div className="space-y-4">
          <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Sample regimen</p>
          <table className="w-full rounded-t-2xl overflow-hidden">
            <thead>
              <tr className="border border-content-lighter bg-content-lighter">
                <th>Weeks</th>
                <th>Quantity</th>
                <th>Peanuts</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border border-content-lighter">
                <td>40th - 42nd</td>
                <td>2 capsules</td>
                <td>256 mg</td>
              </tr>
              <tr className="border border-content-lighter">
                <td>43rd - 45th</td>
                <td>3 capsules</td>
                <td>384 mg</td>
              </tr>
              <tr className="border border-content-lighter">
                <td>46th - 48th</td>
                <td>4 capsules</td>
                <td>512 mg</td>
              </tr>
              <tr className="border border-content-lighter">
                <td>49th - 51st</td>
                <td>6 capsules</td>
                <td>768 mg</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

function MilestonesPhase3() {
  return (
    <div className="bg-brand-primary-light rounded-2xl w-full py-8 px-4 md:px-8 text-left space-y-6">
      <h2 className="text-2xl">Phase 3: Maintain</h2>
      <div>
        <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Objective</p>
        <p>This final step ensures that their body maintains its newfound peanut tolerance indefinitely!</p>
      </div>
      <div>
        <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Product</p>
        <p>800mg organic peanut capsule</p>
      </div>
      <div className="space-y-4">
        <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Sample regimen</p>
        <table className="w-full rounded-t-2xl overflow-hidden">
          <thead>
            <tr className="border border-content-lighter bg-content-lighter">
              <th>Week</th>
              <th>Quantity</th>
              <th>Peanuts</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border border-content-lighter">
              <td>52nd onward</td>
              <td>1 capsules</td>
              <td>800 mg</td>
            </tr>
          </tbody>
        </table>
        <p>Research indicates that a maintenance dose of 800 mg of peanut flour generally equips consumers to tolerate up to an additional 2400 mg. But please, continue to exercise caution and carry your usual allergy prevention measures.</p>
      </div>
      <div className="space-y-2">
        <p className="text-xs underline font-semibold uppercase flex-grow">Why maintenance?</p>
        <p>Just like flu vaccines, the effects of peanut immunotherapy may fade over time without continued consumption. This is why we recommend a consistent maintenance phase to keep your kid's immunity strong.</p>
      </div>
    </div>
  )
}