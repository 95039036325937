import React, { useState } from 'react'
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { PreSignInHeader, DefaultFooter } from './common/HeaderAndFooter';
import InputField from "./common/InputField";
import { ALLAY_TOKEN } from './common/Cookie';
import API from '../api';
import { ACCOUNT_URL } from './common/Navigation';


export default function AccountShippingAddressEditPage(props) {
  let navigate = useNavigate();

  const { state } = useLocation();
  const [cookies] = useCookies([ALLAY_TOKEN]);

  const SHIPPING_VALIDATION_ERROR_MSG = "Please enter a valid shipping address";
  const SHIPPING_UPDATE_INTERNAL_ERROR_MSG = "Oops, looks like something went wrong. Please try again."
  const [shippingError, setShippingError] = useState('');
  const [shippingFirstName, setShippingFirstName] = useState(state.shipping_first_name);
  const [shippingLastName, setShippingLastName] = useState(state.shipping_last_name);
  const [shippingStreet1, setShippingStreet1] = useState(state.shipping_street1);
  const [shippingStreet2, setShippingStreet2] = useState(state.shipping_street2);
  const [shippingCity, setShippingCity] = useState(state.shipping_city);
  const [shippingRegion, setShippingRegion] = useState(state.shipping_region);
  const [shippingPostalCode, setShippingPostalCode] = useState(state.shipping_postal_code);
  const [disabledCta, setDisableCta] = useState(false);
  const [didFinishUpdated, setDidFinishUpdated] = useState(false);

  function handleEditShippingAddress() {
    if (validateShippingAddress()) {
      setShippingError("");
      setDisableCta(true);
      API.updateUserShippingAddress(cookies[ALLAY_TOKEN], {
        "shipping_first_name": shippingFirstName,
        "shipping_last_name": shippingLastName,
        "shipping_street1": shippingStreet1,
        "shipping_street2": shippingStreet2,
        "shipping_city": shippingCity,
        "shipping_region": shippingRegion,
        "shipping_postal_code": shippingPostalCode
      }).then(response => {
        if (response.ok) {
          setDidFinishUpdated(true);
        } else {
          throw response;
        }
      }).catch(e => {
        setShippingError(SHIPPING_UPDATE_INTERNAL_ERROR_MSG);
        setDisableCta(false);
        console.log(e);
      });
    } else {
      setShippingError(SHIPPING_VALIDATION_ERROR_MSG);
    }
  }

  function validateShippingAddress() {
    const postalCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
    return shippingFirstName.length > 0 && shippingLastName.length > 0 && shippingStreet1.length > 4 && shippingCity.length > 2 && shippingRegion.length > 1 && postalCodeRegex.test(shippingPostalCode);
  }

  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container relative rounded-t-3xl bg-brand-primary-regular text-center content-center py-16">
          <div className="max-w-lg mx-auto space-y-8">
            <div className="text-left md:bg-brand-primary-light md:rounded-2xl md:w-full md:p-8">
              {didFinishUpdated
                ? <div className="space-y-4 text-center">
                  <p className="font-semibold">Shipping address updated</p>
                  <button className="btn-outline" onClick={() => navigate(ACCOUNT_URL)}>Go back</button>
                </div>
                : <div className="space-y-4">
                  <p className="font-semibold pb-2 uppercase text-center">Edit your shipping address</p>
                  <div className="flex space-x-4">
                    <InputField
                      className="flex-grow"
                      label="First name"
                      name="shippingFirstName"
                      value={shippingFirstName}
                      type="text"
                      autocomplete="given-name"
                      x_autocompletetype="given-name"
                      onChange={(event) => setShippingFirstName(event.target.value)} />
                    <InputField
                      className="flex-grow"
                      label="Last name"
                      name="shippingLastName"
                      value={shippingLastName}
                      type="text"
                      autocomplete="family-name"
                      x_autocompletetype="family-name"
                      onChange={(event) => setShippingLastName(event.target.value)} />
                  </div>
                  <InputField
                    className="flex-grow"
                    label="Address"
                    name="shippingAddress1"
                    value={shippingStreet1}
                    type="text"
                    autocomplete="address-line1"
                    x_autocompletetype="address-line1"
                    onChange={(event) => setShippingStreet1(event.target.value)} />
                  <InputField
                    className="max-w-full"
                    label="Apt, suite, etc. (optional)"
                    name="shippingAddress2"
                    value={shippingStreet2}
                    type="text"
                    autocomplete="address-line2"
                    x_autocompletetype="address-line2"
                    attention={shippingError.length > 0}
                    onChange={(event) => setShippingStreet2(event.target.value)} />
                  <InputField
                    className="w-full"
                    label="City"
                    name="shippingCity"
                    value={shippingCity}
                    type="text"
                    autocomplete="locality"
                    x_autocompletetype="locality"
                    onChange={(event) => setShippingCity(event.target.value)} />
                  <div className="flex space-x-4 pb-8">
                    <InputField
                      className="flex-grow"
                      label="State"
                      name="shippingRegion"
                      value={shippingRegion}
                      type="text"
                      autocomplete="region"
                      x_autocompletetype="region"
                      attention={shippingError.length > 0}
                      onChange={(event) => setShippingRegion(event.target.value)} />
                    <InputField
                      className="max-w-50pc"
                      label="Zip code"
                      name="shippingPostalCode"
                      value={shippingPostalCode}
                      type="text"
                      autocomplete="postal-code"
                      x_autocompletetype="postal-code"
                      onChange={(event) => setShippingPostalCode(event.target.value)} />
                  </div>
                  <div className="space-y-2">
                    {shippingError.length > 0 &&
                      <p className="text-content-error">{shippingError}</p>
                    }
                    <button className="btn-primary w-full" disabled={disabledCta} onClick={handleEditShippingAddress}>Save</button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  )
}