import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { RWebShare } from "react-web-share";
import Skeleton from "react-loading-skeleton";
import { HiOutlineMail, HiOutlineUserCircle, HiOutlineShare } from 'react-icons/hi';
import { LiaShippingFastSolid, LiaCreditCard } from 'react-icons/lia';
import { PiReceipt, PiPill, PiWarningCircleBold, PiWarning } from 'react-icons/pi';
import { BiDetail } from 'react-icons/bi';
import { MdOutlineBorderColor } from 'react-icons/md';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { ALLAY_TOKEN, ALLAY_REFRESH_TOKEN } from './common/Cookie';
import { PreSignInHeader, DefaultFooter } from './common/HeaderAndFooter';
import API from '../api';
import { ACCOUNT_BILLING_EDIT_URL, ACCOUNT_TRANSACTION_DETAIL_URL, ACCOUNT_SHIPPING_ADDESS_EDIT_URL } from './common/Navigation';
import { PAYMENT_PLAN_MONTHLY_NAME } from './CheckoutPage';

export default function AccountPage() {
  const [cookies, removeCookie] = useCookies([ALLAY_TOKEN]);
  const [userInfo, setUserInfo] = useState(null);
  const [userSubscriptionInfo, setUserSubscriptionInfo] = useState(null);

  useEffect(() => {
    API.getUserInfo(cookies[ALLAY_TOKEN]).then(response => {
      if (response.ok) {
        response.json().then(info => {
          setUserInfo(info.data);
        });
      } else if (response.status === 401) {
        removeCookie(ALLAY_TOKEN);
        removeCookie(ALLAY_REFRESH_TOKEN);
      }
    });

    API.getUserSubscriptionInfo(cookies[ALLAY_TOKEN]).then(response => {
      if (response.ok) {
        response.json().then(subscriptionInfo => {
          setUserSubscriptionInfo(subscriptionInfo.data);
        })
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  function handleSignOut() {
    API.signOut({ "refresh": cookies[ALLAY_REFRESH_TOKEN] });
    removeCookie(ALLAY_TOKEN);
    removeCookie(ALLAY_REFRESH_TOKEN);
  }

  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container relative rounded-t-3xl bg-brand-primary-regular text-center content-center py-16">
          <div className="max-w-lg mx-auto space-y-8">
            <AccountPageUserInfoSection userInfo={userInfo} />
            <AccountPageUserSubscriptionDetailSection userSubscriptionInfo={userSubscriptionInfo} />
            <AccountPageUserShippingAddressSection userSubscriptionInfo={userSubscriptionInfo} />
            <button className="btn-primary w-full" onClick={handleSignOut}>Sign out</button>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  )
}

function AccountPageUserInfoSection(props) {
  return (
    <div>
      <h1 className="text-3xl text-center text-content-regular pb-8">{props.userInfo ? `Welcome back ${props.userInfo.name}!` : <Skeleton />}</h1>
      <div className="bg-brand-primary-light rounded-2xl w-full p-8 text-left">
        {props.userInfo ?
          <div className="space-y-4">
            <p className="text-xs underline font-semibold pb-2 uppercase">Account information</p>
            <p className="flex"><span className="pt-1 pr-2 text-content-light"><HiOutlineMail size="16" /></span>{props.userInfo.email}</p>
            <p className="flex"><span className="pt-1 pr-2 text-content-light"><HiOutlineUserCircle size="16" /></span><span className="text-content-light pr-1">member since</span> {moment.unix(props.userInfo.creation_timestamp).format("MMM DD, YYYY")}</p>
            <div className="flex">
              <span className="pt-1 pr-2 text-content-light"><HiOutlineShare size="16" /></span>
              <div className="space-y-2">
                {/* eslint-disable-next-line react/jsx-no-comment-textnodes  */}
                <p className="text-sm font-bold">{window.location.protocol}//{window.location.hostname}{window.location.port.length > 0 && `:${window.location.port}`}?ref={props.userInfo.name.replace(/\s/g, '')}&refid={props.userInfo.referral_code}</p>
                <p className="text-sm text-content-light pb-2">Introduce your friends to Allay's research-backed approach of peanut oral immunotherapy</p>
                <RWebShare
                  data={{
                    text: "Introduce your friend to Allay research-backed approach of peanut oral immunotherapy",
                    url: `${window.location.protocol}//${window.location.hostname}${window.location.port.length > 0 && ':' + window.location.port}?ref=${props.userInfo.name.replace(/\s/g, '')}&refid=${props.userInfo.referral_code}`,
                    title: "Share Allay with your friend and make a direct impact to their lives.",
                  }}
                  closeText="I'll do it later"
                  onClick={() => console.log("shared")}
                >
                  <button className="btn-primary btn-style-thin">Invite now</button>
                </RWebShare>
              </div>
            </div>
          </div>
          : <div className="space-y-4">
            <p><Skeleton /></p>
            <p><Skeleton /></p>
            <p><Skeleton /></p>
            <p><Skeleton count={3} /></p>
          </div>
        }
      </div>
    </div >
  );
}

function AccountPageUserShippingAddressSection(props) {
  let navigate = useNavigate();

  return (
    <div className="bg-brand-primary-light rounded-2xl w-full p-8 text-left">
      {props.userSubscriptionInfo ?
        <div className="space-y-4 divide-y divide-solid divide-content-lighter">
          <div className="space-y-4">
            <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Shipping information</p>
            {(props.userSubscriptionInfo.detail.status === 'active' || (moment(props.userSubscriptionInfo.expiration_date) > moment(props.userSubscriptionInfo.next_shipping_date))) &&
              <div className="flex">
                <span className="pt-1 pr-2 text-content-light"><BiDetail size="16" /></span>
                <div>
                  <p>Next delivery @ <span className="font-semibold">{moment(props.userSubscriptionInfo.next_shipping_date).format("MMM DD, YYYY")}</span></p>
                  <p><span className="font-semibold capitalize">{props.userSubscriptionInfo.next_shipment_product_name} -</span> {props.userSubscriptionInfo.next_shipment_product_ingredient_weight}mg peanut capsules</p>
                </div>
              </div>
            }
            <div className="flex">
              <span className="pt-1 pr-2 text-content-light"><LiaShippingFastSolid size="16" /></span>
              <div>
                <p className="font-semibold capitalize">{props.userSubscriptionInfo.shipping_info.shipping_first_name} {props.userSubscriptionInfo.shipping_info.shipping_last_name}</p>
                <p>{props.userSubscriptionInfo.shipping_info.shipping_street1}{props.userSubscriptionInfo.shipping_info.shipping_street2.length > 0 && (', ' + props.userSubscriptionInfo.shipping_info.shipping_street2)}</p>
                <p>{props.userSubscriptionInfo.shipping_info.shipping_city}, {props.userSubscriptionInfo.shipping_info.shipping_region} {props.userSubscriptionInfo.shipping_info.shipping_postal_code}</p>
                <button className="btn-link mt-2" onClick={() => navigate(ACCOUNT_SHIPPING_ADDESS_EDIT_URL, { state: props.userSubscriptionInfo.shipping_info })}>Edit address</button>
              </div>
            </div>
          </div>
          {props.userSubscriptionInfo.previous_shipments?.length > 0 &&
            <div className="space-y-4">
              <p className="text-xs underline font-semibold pt-6 uppercase flex-grow">Delivery history</p>

              <div className="space-y-4 divide-y divide-solid divide-content-lighter w-full">
                {props.userSubscriptionInfo.previous_shipments.map((shipment, index) => 
                  <div key={index} className={`cursor-pointer ${index !== 0 && 'pt-4'}`} onClick={() => window.open(shipment.shipping_tracking_link, '_blank')}>
                    <div className="flex">
                      <p className="flex-grow">{shipment.product_ingredient_weight}mg peanut capsules</p>
                      <p className="font-semibold">{moment.unix(shipment.creation_timestamp).format("MMM DD, YYYY")}</p>
                    </div>
                    <p className="text-sm">Tracking #{shipment.shipping_tracking_id}</p>
                  </div>
                )}
              </div>
            </div>
          }
        </div>
        : <div className="space-y-4">
          <p><Skeleton /></p>
          <p><Skeleton count={4} /></p>
        </div>
      }
    </div>
  );
}

function AccountPageUserSubscriptionDetailSection(props) {
  let navigate = useNavigate();

  return (
    <div className="bg-brand-primary-light rounded-2xl w-full p-8 text-left">
      {props.userSubscriptionInfo ?
        <div className="space-y-4 divide-y divide-solid divide-content-lighter">
          <div className="space-y-2">
            <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Subscription detail</p>
            <div className="flex">
              <span className="pt-1 pr-2 text-content-light capitalize"><MdOutlineBorderColor size="16" /></span>
              <p className="font-semibold"><span className="text-content-light font-normal">Order </span>#{props.userSubscriptionInfo.payment_gateway_subscription_id}</p>
            </div>
            <div className="flex">
              <span className="pt-1 pr-2 text-content-light"><BiDetail size="16" /></span>
              <div>
                <p className="font-semibold capitalize">{props.userSubscriptionInfo.subscription_type.toLowerCase()} <span>plan</span></p>
                <p>
                  ${props.userSubscriptionInfo.detail.amount}
                  <span className="text-content-light font-normal"> every {props.userSubscriptionInfo.subscription_type === PAYMENT_PLAN_MONTHLY_NAME ? 'month' : '12 months'} starting at </span>
                  {moment(props.userSubscriptionInfo.detail.start_date).format("MMM DD, YYYY")}
                </p>
              </div>
            </div>
            {props.userSubscriptionInfo.current_product_level >= 0 &&
              <div className="flex">
                <span className="pt-1 pr-2 text-content-light"><PiPill size="16" /></span>
                <div>
                  <p><span className="font-semibold capitalize">{props.userSubscriptionInfo.current_product_name} -</span> {props.userSubscriptionInfo.current_product_ingredient_weight}mg peanut capsules</p>
                </div>
              </div>
            }
            <div className={'flex'}>
              <span className="pt-1 pr-2 text-content-light capitalize"><AiOutlineCheckCircle size="16" /></span>
              <p className={`flex-grow font-semibold uppercase ${props.userSubscriptionInfo.detail.status === 'active' ? 'text-brand-secondary-regular' : 'text-content-error'}`}>{props.userSubscriptionInfo.detail.status}</p>
              {props.userSubscriptionInfo.detail.status === 'active' &&
                <AccountPageUnsubscribeModal />
              }
            </div>
          </div>

          <div className="space-y-2">
            <p className="text-xs underline font-semibold pt-6 pb-2 uppercase flex-grow">Billing information</p>
            <p className="flex"><span className="pt-1 pr-2 text-content-light"><LiaCreditCard size="16" /></span><span className="text-content-light pr-1">{props.userSubscriptionInfo.billing_info.masked_card_type} ending in</span> <span className="font-semibold">{props.userSubscriptionInfo.billing_info.masked_card_number.replace(/X/g, '*')}</span></p>
            <div className="flex">
              <span className="pt-1 pr-2 text-content-light capitalize"><PiReceipt size="16" /></span>
              <div>
                <p className="text-content-light font-xs">Billed to</p>
                <p className="font-semibold capitalize">{props.userSubscriptionInfo.billing_info.billing_first_name} {props.userSubscriptionInfo.billing_info.billing_last_name}</p>
                <p>{props.userSubscriptionInfo.billing_info.billing_street1}</p>
                <p>{props.userSubscriptionInfo.billing_info.billing_city}, {props.userSubscriptionInfo.billing_info.billing_region} {props.userSubscriptionInfo.billing_info.billing_postal_code}</p>
                <button className="btn-link mt-2" onClick={() => navigate(ACCOUNT_BILLING_EDIT_URL, { state: props.userSubscriptionInfo.billing_info })}>Edit billing account</button>
              </div>
            </div>
          </div>

          {props.userSubscriptionInfo.payments.length > 0 &&
            <div className="space-y-2">
              <p className="text-xs underline font-semibold pt-6 pb-2 uppercase flex-grow">Payment history</p>
              {props.userSubscriptionInfo.payments.map((payment, index) =>
                <div className={`py-2 cursor-pointer ${index !== props.userSubscriptionInfo.payments.length - 1 && 'border-b border-content-lighter'}`} onClick={() => navigate(ACCOUNT_TRANSACTION_DETAIL_URL, { state: { transId: payment.id } })} key={index}>
                  <div className="flex">
                    <p className="flex-grow">{moment(payment.created_at).format("MMM DD, YYYY")}</p>
                    <p className="font-semibold">${props.userSubscriptionInfo.detail.amount}</p>
                  </div>
                  <p className="text-sm"><span className="text-content-light pr-1">{payment.response}</span> #{payment.id} </p>
                </div>
              )}
            </div>
          }
        </div>
        : <div className="space-y-4">
          <p><Skeleton /></p>
          <p><Skeleton /></p>
          <p><Skeleton count={2} /></p>
          <p><Skeleton /></p>
          <p><Skeleton count={2} /></p>
        </div>
      }
    </div>
  );
}

function AccountPageUnsubscribeModal() {
  const [cookies] = useCookies([ALLAY_TOKEN]);

  const [showModal, setShowModal] = useState(false);
  const [isUnsubscribing, setIsUnsubscribing] = useState(false);
  let modalRef = useRef(null);

  function handleClickOutside(event) {
    if (modalRef && !modalRef.current.contains(event.target) && !isUnsubscribing) {
      setShowModal(false);
    }
  }

  function performUnsubscribeAction() {
    setIsUnsubscribing(true);
    API.unsubcribe(cookies[ALLAY_TOKEN]).then(response => {
      if (response.ok) {
        setShowModal(false);
        setIsUnsubscribing(false);
        window.location.reload(false);
      } else {
        setIsUnsubscribing(false)
      }
    });
  }

  return (
    <div>
      <div tabIndex="-1" aria-hidden="true" className={`fixed top-0 left-0 w-screen h-screen z-50 justify-center items-center backdrop-blur-sm ${!showModal && 'hidden'}`} onClick={handleClickOutside}>
        <div className="relative p-4 w-full max-w-lg top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2" ref={modalRef}>
          <div className="relative p-4 text-center bg-brand-tertiary-darker rounded-lg shadow">
            <button type="button" className="text-content-white absolute top-2.5 right-2.5 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" disabled={isUnsubscribing} onClick={() => setShowModal(false)}>
              <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="text-content-white flex justify-center py-4"><PiWarningCircleBold size="64" fill="currentColor" /></div>
            <p className="text-lg font-semibold text-content-white">Confirm your cancellation</p>
            <p className="mt-2 mb-4 text-content-lighter">Are you sure you want to cancel your Allay subscription?</p>

            <div className="p-4 mb-8 text-left rounded-lg bg-brand-primary-regular w-full space-y-2">
              <p className="text-content-error flex"><PiWarning fill="currentColor" size="24" className="mr-2" />Warning</p>
              <p className="text-content-error">Subscription cancelation is irreversible action. Please contact our support team if you want to pause or delay product delivery instead.</p>
            </div>
            <div className="flex justify-center items-center space-x-4">
              <button className="btn-outline text-content-white border-content-white" onClick={() => setShowModal(false)} disabled={isUnsubscribing}>I'll think about it</button>
              <button className="btn-secondary bg-content-error text-content-white" disabled={isUnsubscribing} onClick={performUnsubscribeAction}>Yes, I'm sure</button>
            </div>
          </div>
        </div>
      </div>
      <button className="btn-link btn-style-thin -mt-1" onClick={() => setShowModal(true)}>Cancel</button>
    </div>
  )
}
