import { useNavigate } from "react-router-dom";
import { PromotionHeader, PreSignInHeader, DefaultFooter } from './common/HeaderAndFooter';
import { DOWNLOAD_APP_URL, FAQ_URL, LEARN_MORE_URL, MILESTONES_URL, PRODUCT_URL } from "./common/Navigation";
import { ExpandableFAQItem } from './FaqPage';

export default function OverviewPage() {
  return (
    <div className="bg-brand-tertiary-darker">
      <PromotionHeader />
      <PreSignInHeader />
      <div className="app-container">
        <OverviewPageHeadingSection />
        <OverviewPageResearchSummarySection />
        <OverviewPageHowItWorksSection />
        <OverviewPageWhyAllaySection />
        <OverviewPageFAQSection />
      </div>
      <DefaultFooter />
    </div>
  )
}

function OverviewPageHeadingSection() {
  let navigate = useNavigate();
  return (
    <div className="section-container relative rounded-t-3xl bg-brand-primary-regular text-center content-center pt-24 pb-24 md:pt-40 md:pb-32">
      <h1 className="text-content-regular max-w-[23ch] mx-auto text-2xl md:text-3xl lg:text-4xl">
        Peanut allergy can’t be cured. But the severity of its reactions can be <span className="text-brand-tertiary-regular">allayed</span>.
          </h1>
      <p className="text-content-regular font-medium max-w-[35ch] mx-auto text-lg md:text-xl">Allay brings the research-backed approach of peanut oral immunotherapy to your daily routines.*</p>
      <img className="md:hidden w-[70%] ml-[15%]" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1698092203/allay/product_alone.png" alt="Allay product bottle" />
      <div className="inline-block mx-auto space-x-4">
        <button className="btn-primary" onClick={() => navigate(PRODUCT_URL)}>Shop now</button>
        <button className="btn-outline" onClick={() => navigate(LEARN_MORE_URL)}>Learn more</button>
      </div>
      <div className="absolute -bottom-10 lg:-bottom-14 -right-8 z-20 hidden md:block">
        <img className="md:rotate-[25deg] w-[18rem] lg:w-[24rem]" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1698092203/allay/product_alone.png" alt="Allay product bottle" />
      </div>
      <div className="absolute -top-6 right-0 rounded-t-3xl overflow-hidden hidden md:block">
        <img className="w-[10rem] md:w-[12rem] lg:w-[16rem]" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1698093612/allay/banner-asset-2.png" alt="Decorative" />
      </div>
      <div className="absolute top-32 left-0 rounded-t-3xl overflow-hidden hidden md:block">
        <img className="md:w-48 lg:w-64" src="https://res.cloudinary.com/dqafyoayf/image/upload/v1698094389/allay/banner-asset-1.png" alt="Allay product decoration"/>
      </div>
    </div>
  )
}

function OverviewPageResearchSummarySection() {
  return (
    <div className="section-container overflow-hidden space-y-0 relative bg-brand-secondary-regular text-center content-center py-24 lg:py-36">
      <p className="text-lg z-20 pb-6 md:text-xl font-bold font-content text-content-lightest">Did you know?</p>
      <p className="max-w-[46ch] z-20 mx-auto text-xl md:text-2xl lg:text-3xl font-medium text-content-lightest">Latest research shows that peanut oral immunotherapy could help many with peanut-allergy achieve desensitization and even sustained unresponsiveness in some.</p>
      <div className="absolute bottom-0 left-0 right-0">
        <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-24 lg:h-36 text-brand-secondary-light" preserveAspectRatio="none" viewBox="0 0 1360 175" fill="none">
          <path d="M1360 173C805 173 26 175 -2 175C119.01 114.438 365 1.00001 827.529 72.9074C1059.19 108.923 1187.03 0 1360 0V173Z" fill="currentColor" />
        </svg>
      </div>
      <div className="absolute bottom-0 left-1/4 sm:left-auto sm:right-0 top-0">
        <svg className="h-full" viewBox="800 0 560 410" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_375_1819)">
            <path className="text-brand-tertiary-light" opacity="0.2" d="M1170.67 93.2262C990.933 145.55 884.889 32.0516 864 -19.0357L1424 -146.429L1484.67 334.345C1429.56 442.214 1210.71 660.711 1054 504C915 365 1200.84 294.825 1290.67 205C1358.67 137 1310 52.6636 1170.67 93.2262Z" fill="currentColor" />
            <circle cx="863.5" cy="-0.5" r="50.5" fill="#4565FF" />
            <path className="" fillRule="evenodd" clipRule="evenodd" d="M900.57 33.7942C881.483 14.6324 868.874 -4.66947 863 -19.0358L905.41 -28.6836C910.834 -20.6339 914 -10.9367 914 -0.500071C914 12.739 908.905 24.7883 900.57 33.7942Z" fill="#040B1B" />
          </g>
          <defs>
            <clipPath id="clip0_375_1819">
              <rect width="1360" height="410" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  )
}

function OverviewPageHowItWorksSection() {
  let navigate = useNavigate();

  return (
    <div className="section-container bg-brand-primary-regular py-24 text-center">
      <h2 className="text-content-regular text-center pb-12 text-3xl lg:text-4xl">Introducing Allay Complete Plan</h2>

      <div className="grid grid-cols-3 gap-8 max-w-6xl mx-auto">
        <div className="space-y-4 text-center py-4 col-span-3 md:col-span-1">
          <img className="object-cover w-1/2 mx-auto" src="https://res.cloudinary.com/dqafyoayf/image/upload/w_256,c_fill,ar_1:1,g_auto,r_max/v1698088396/allay/home_4.png" alt="Start with Allay Subscription" />
          <p className="font-semibold text-xl">Start simply</p>
          <p>We begin with a safe, minimal dose of 2mg, which <a className="underline font-semibold" href="https://www.allergicliving.com/2022/09/23/test-shows-how-much-peanut-an-allergic-child-can-tolerate/" target="_blank" rel="noreferrer">studies</a> show is well tolerated by 92% of individuals with severe peanut allergies.</p>
        </div>
        <div className="space-y-4 text-center py-4 col-span-3 md:col-span-1">
          <img className="object-cover w-1/2 mx-auto" src="https://res.cloudinary.com/dqafyoayf/image/upload/w_256,c_fill,ar_1:1,g_auto,r_max/v1698088396/allay/product_4.png"  alt="progress gradually" />
          <p className="font-semibold text-xl">Progress gradually</p>
          <p>Safely increase your peanut intake through 3 phases until you can take 10 peanuts without reactions.</p>
        </div>
        <div className="space-y-4 text-center py-4 col-span-3 md:col-span-1">
          <img className="object-cover w-1/2 mx-auto" src="https://res.cloudinary.com/dqafyoayf/image/upload/w_256,c_fill,ar_1:1,g_auto,r_max/v1698087055/allay/about_hnypy7.png"  alt="confident living" />
          <p className="font-semibold text-xl">Live confidently</p>
          <p>Use our app to guide you through a worry-free path to managing your peanut sensitivity.</p>
        </div>
      </div>
      <button className="btn-outline" onClick={() => navigate(MILESTONES_URL)}>Learn about the Allay journey</button>
    </div>
  )
}

function OverviewPageWhyAllaySection() {
  let navigate = useNavigate();

  return (
    <div className="section-container bg-brand-tertiary-dark py-24 text-center">
      <h2 className="text-content-white text-center text-3xl lg:text-4xl">Why Allay?</h2>

      <div className="pt-10 space-y-4 max-w-6xl mx-auto">
        <div className="flex flex-col lg:flex-row space-y-4 space-x-0 lg:space-x-4 lg:space-y-0">
          <InformationCard large>
            <div className="mt-auto cursor-pointer" onClick={() => navigate(LEARN_MORE_URL)}>
              <div className="max-w-md space-y-4 pt-12">
                <h3 className="text-2xl font-semibold text-content-regular">Proven science</h3>
                <p>Allay leverages the science-backed approach of immunotherapy. Our carefully designed supplement capsules deliver controlled amounts of peanut powder, providing a methodical way to increase tolerance.</p>
              </div>
            </div>
          </InformationCard>
          <InformationCard small>
            <div className="mt-auto cursor-pointer" onClick={() => navigate(PRODUCT_URL)}>
              <div className="max-w-xs space-y-4 pt-12">
                <h3 className="text-2xl font-semibold text-content-regular">Affordable solution</h3>
                <p>Allay peanut supplement offers a flexible and cost-effective alternative to traditional immunotherapy.</p>
              </div>
            </div>
          </InformationCard>
        </div>
        <div className="flex flex-col lg:flex-row space-y-4 space-x-0 lg:space-x-4 lg:space-y-0">
          <InformationCard small>
            <div className="mt-auto cursor-pointer" onClick={() => navigate(MILESTONES_URL)}>
              <div className="max-w-xs space-y-4 pt-12">
                <h3 className="text-2xl font-semibold text-content-regular">Easy to use</h3>
                <p>Allay fits effortlessly into your daily routine. You can take the supplement capsule directly or break it to mix with food.</p>
              </div>
            </div>
          </InformationCard>
          <div className='space-y-3 w-full lg:w-8/12'>
            <InformationCard full>
              <div className="mt-auto cursor-pointer"  onClick={() => navigate(DOWNLOAD_APP_URL)}>
                <div className="max-w-md space-y-4 pt-12">
                  <h3 className="text-2xl font-semibold text-content-regular">Mobile app</h3>
                  <p>Stay on track effortlessly with Allay's mobile app. It reminds and helps you log intake as well as adapts to your feedback, guiding you through each phase.</p>
                </div>
              </div>
            </InformationCard>
            <InformationCard full>
              <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center w-full cursor-pointer" onClick={() => navigate(LEARN_MORE_URL)}>
                <h3 className="text-2xl font-semibold text-content-regular flex-grow">Learn more how Allay works</h3>
              </div>
            </InformationCard>
          </div>
        </div>
      </div>
    </div>
  )
}

function OverviewPageFAQSection() {
  let navigate = useNavigate();

  return (
    <div className="section-container overflow-hidden space-y-0 relative bg-brand-tertiary-light text-center content-center py-20">
      <div className="text-left max-w-6xl mx-auto">
        <p className="text-xl font-semibold text-content-regular">Still need some things answered?</p>
        <h3 className="text-3xl font-semibold text-content-regular pt-2 pb-8">Frequently Asked Questions </h3>
        <ExpandableFAQItem
          question="What are the potential side effects when starting Allay?"
          expanded={true}>
          <p className="text-content-regular pt-4">As Allay contains a carefully controlled amount of peanuts, initial consumption or increases in dosage might cause mild reactions in some individuals, especially those with known peanut sensitivities. While severe reactions are extremely rare, they are more likely to occur during initial introduction to Allay or upon dosage increases.</p>
          <p className="text-content-regular pt-4">Please exercise caution during the first few days of any dosage change. If anyone taking Allay shows signs of difficulty breathing, immediately administer an EpiPen or similar epinephrine autoinjector and seek emergency medical attention. Always consult with a healthcare professional before starting or changing your Allay regimen.</p>
        </ExpandableFAQItem>
        <ExpandableFAQItem
          question="When can I expect to see results from using Allay?">
          <p className="text-content-regular pt-4">While individual experiences can vary, some people may start to notice changes as early as the first week, even if they are not immediately apparent due to the controlled quantity in Allay. Please refer to the <span className="underline font-semibold" onClick={() => navigate(MILESTONES_URL)}> milestone chart</span> for a better understanding of the typical progression. As always, consult your healthcare professional with any questions or concerns.</p>
        </ExpandableFAQItem>
        <ExpandableFAQItem
          question="What is the recommended duration for using Allay?">
          <p className="text-content-regular pt-4">Allay is structured around three phases, and the usage duration can vary depending on your individual progress and tolerance. While early use might not lead to complete remission for everyone, we generally recommend maintaining consistent use of Allay for optimal long-term results and peace of mind. As always, consult your healthcare professional for personalized advice.</p>
        </ExpandableFAQItem>
        <ExpandableFAQItem
          question="How does the Allay app work?">
          <p className="text-content-regular pt-4">Our Allay app is designed as a convenient tool to log your product usage and any reactions, and receive AI-driven dosage recommendations. It also ensures a seamless auto-refill and ordering process, making your journey with Allay easier and more manageable.</p>
        </ExpandableFAQItem>
        <button className="mt-6 underline" onClick={() => navigate(FAQ_URL)}>Read all</button>
      </div>
    </div>
  )
}

function InformationCard(props) {
  return (
    <div className={`bg-brand-primary-light bg-right-top bg-contain bg-no-repeat rounded-2xl p-6 text-left flex ${props.onClick && 'cursor-pointer'} ${props.full && 'w-full'} ${props.small && 'w-full lg:w-4/12'} ${props.large && 'w-full lg:w-8/12'}`} onClick={props.onClick}>
      {props.children}
    </div>
  )
}