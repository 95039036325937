import { useEffect } from "react";
import {
  HashRouter, Routes, Route, useLocation, Navigate
} from "react-router-dom";
import { useCookies } from 'react-cookie';
import ProductPage from './components/ProductPage';
import CheckoutPage from './components/CheckoutPage';
import FaqPage from './components/FaqPage';
import HomePage from './components/HomePage';
import LearnMorePage from './components/LearnMorePage';
import MilestonesPage from './components/MilestonesPage';
import PrivacyPage from "./components/PrivacyPage";
import PrivacyOptOutPage from './components/PrivacyOptOutPage';
import TermsPage from "./components/TermsPage";
import NotFoundPage from './components/NotFoundPage';
import AccountPage from "./components/AccountPage";
import AccountShippingAddressEditPage from "./components/AccountShippingAddressEditPage";
import AccountBillingEditPage from "./components/AccountBillingEditPage";
import AccountTransactionDetailPage from "./components/AccountTransactionDetailPage";
import LoginPage from "./components/LoginPage";
import OrderDetailPage from "./components/OrderDetailPage";
import { CHECKOUT_URL, HOME_URL, LEARN_MORE_URL, PRIVACY_URL, PRIVACY_OPT_OUT_URL, TERMS_URL, ACCOUNT_URL, LOGIN_URL, ACCOUNT_SHIPPING_ADDESS_EDIT_URL, ORDER_DETAIL_URL, ACCOUNT_BILLING_EDIT_URL, PRODUCT_URL, FAQ_URL, MILESTONES_URL, RESEARCH_OIT_URL, ACCOUNT_TRANSACTION_DETAIL_URL, DOWNLOAD_APP_URL, OVERVIEW_URL, DELETE_ACCOUNT_LOGIN_URL, DELETE_ACCOUNT_CONFIRMATION_URL } from './components/common/Navigation';
import { ALLAY_TOKEN, IsSignedIn } from './components/common/Cookie';
import ResearchOITPage from "./components/ResearchOITPage";
import DownloadAppPage from "./components/DownloadAppPage";
import OverviewPage from "./components/OverviewPage";
import DeleteAccountLoginPage from "./components/DeleteAccountLoginPage";
import DeleteAccountConfirmationPage from "./components/DeleteAccountConfirmationUrl";

export default function App() {
  return (
    <div className="bg-brand-tertiary-darker m-auto w-full min-h-screen">
      <HashRouter>
        <ScrollToTop />
        <Routes>
          <Route exact path={HOME_URL} element={<HomePage />} />
          <Route path={CHECKOUT_URL} element={<CheckoutPage />} />
          <Route path={DELETE_ACCOUNT_CONFIRMATION_URL} element={<DeleteAccountConfirmationPage />} />
          <Route path={DELETE_ACCOUNT_LOGIN_URL} element={<DeleteAccountLoginPage />} />
          <Route path={DOWNLOAD_APP_URL} element={<DownloadAppPage />} />
          <Route path={FAQ_URL} element={<FaqPage />} />
          <Route path={LEARN_MORE_URL} element={<LearnMorePage />} />
          <Route path={LOGIN_URL} element={<LoginPage />} />
          <Route path={MILESTONES_URL} element={<MilestonesPage />} />
          <Route path={ORDER_DETAIL_URL} element={<OrderDetailPage />} />
          <Route path={OVERVIEW_URL} element={<OverviewPage />} />
          <Route path={PRIVACY_URL} element={<PrivacyPage />} />
          <Route path={PRIVACY_OPT_OUT_URL} element={<PrivacyOptOutPage />} />
          <Route path={PRODUCT_URL} element={<ProductPage />} />
          <Route path={RESEARCH_OIT_URL} element={<ResearchOITPage />} />
          <Route path={TERMS_URL} element={<TermsPage />} />
          <Route exact path={ACCOUNT_URL} element={<LoginProtected><AccountPage /></LoginProtected>} />
          <Route path={ACCOUNT_SHIPPING_ADDESS_EDIT_URL} element={<LoginProtected><AccountShippingAddressEditPage /></LoginProtected>} />
          <Route path={ACCOUNT_BILLING_EDIT_URL} element={<LoginProtected><AccountBillingEditPage /></LoginProtected>} />
          <Route path={ACCOUNT_TRANSACTION_DETAIL_URL} element={<LoginProtected><AccountTransactionDetailPage /></LoginProtected>} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function LoginProtected(props) {
  const location = useLocation();
  const [cookies] = useCookies([ALLAY_TOKEN]);

  if (IsSignedIn(cookies)) {
    return props.children;
  } else {
    return <Navigate to={LOGIN_URL} state={{ from: location }} />;
  }
}
