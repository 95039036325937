import React, { useState } from 'react';

export default function InputField(props) {
  const [isActive, setIsActive] = useState(props.value !== undefined && props.value !== '');
  const [isFocused, setIsFocused] = useState(false);

  function handleTextChange(event) {
    setIsActive(event.target.value !== undefined && event.target.value !== '');
    props.onChange(event);
  }

  return(
    <div className={`flex flex-col ${props.className}`}>
      <input 
        className={`h-16 pt-5 px-5 pb-0 w-full outline-none font-semibold font-content text-base text-content-regular border rounded-xl focus:border-2 bg-transparent autofill:bg-transparent ${props.attention ? 'border-content-error' : 'border-content-lighter focus:border-content-regular'}`}
        id={props.name}
        type={props.type}
        value={props.value}
        autoComplete={props.autocomplete}
        x-autocompletetype={props.x_autocompletetype}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={handleTextChange} />
      <label className={`absolute font-content text-base origin-top-left touch-manipulation transition-transform transform translate-x-5 ${props.attention ? 'text-content-error' : 'text-content-light'} ${isActive || isFocused ? "translate-y-3 scale-75" : "translate-y-5 scale-100"}`} htmlFor={props.name}>{props.label}</label>
    </div>
  )
}

export function InputCheckBox(props) {
  const [isChecked, setIsChecked] = useState(props.checked === true);

  function onToggleChange(event) {
    const newCheckedStatus = !isChecked;
    setIsChecked(newCheckedStatus);
    props.onToggle(newCheckedStatus);
  }

  return(
    <div className={`flex items-center ${props.className}`}>
      <input
        type="checkbox"
        className="appearance-none relative peer shrink-0 w-5 h-5 rounded border-2 border-solid border-content-regular bg-content-white checked:bg-content-regular"
        id={props.name}
        checked={isChecked}
        onChange={onToggleChange}/>
      <svg
        className="absolute w-5 h-5 pointer-events-none hidden peer-checked:block stroke-white mt-1 outline-none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="18 4 9 15 6 10"></polyline>
      </svg>
      <label 
        className={`ml-2 cursor-pointer select-none font-content text-base text-content-regular ${props.labelClassName}`}
        htmlFor={props.name}>{props.children}</label>
    </div>
  )
}