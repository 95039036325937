// Keep the link in alphabetical order
export const ACCOUNT_URL = '/account';
export const ACCOUNT_BILLING_EDIT_URL = '/account/billing-info';
export const ACCOUNT_SHIPPING_ADDESS_EDIT_URL = '/account/shipping-address';
export const ACCOUNT_TRANSACTION_DETAIL_URL = '/account/transaction';
export const CHECKOUT_URL = '/checkout';
export const DELETE_ACCOUNT_CONFIRMATION_URL = '/delete-account-confirmation';
export const DELETE_ACCOUNT_LOGIN_URL = '/delete-account';
export const DOWNLOAD_APP_URL = '/download';
export const FAQ_URL = '/faq';
export const HOME_URL = '/';
export const LEARN_MORE_URL = '/learn-more';
export const LOGIN_URL = '/login';
export const MILESTONES_URL = '/milestone';
export const ORDER_DETAIL_URL = '/order-detail';
export const OVERVIEW_URL = '/overview';
export const PRIVACY_URL = '/privacy';
export const PRIVACY_OPT_OUT_URL = '/privacy-opt-out';
export const PRODUCT_URL = "/product";
export const RESEARCH_OIT_URL = '/research-oit';
export const TERMS_URL = '/terms';

export const SOCIAL_MEDIA_FACEBOOK = "https://facebook.com/allaycapsule";
export const SOCIAL_MEDIA_TWITTER = "https://twitter.com/allaycapsule";
export const SOCIAL_MEDIA_INSTAGRAM = "https://instagram.com/allaycapsule";
export const FOUNDER_FACEBOOK = "https://www.facebook.com/minhthaodangnguyen/";
export const FOUNDER_LINKEDIN = "https://www.linkedin.com/in/minh-thao-nguyen-34a2a7a5";
export const APPLE_STORE_APP_LINK = "https://apps.apple.com/us/app/allay-capsules/id6475636493";
export const GOOGLE_PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=com.allaycapsules.labs&amp;hl=en_US&amp;gl=US";


export function NavigateBack(navigate) {
  if (window.history.state && window.history.state.idx > 0) {
    navigate(-1);
  } else {
    navigate(HOME_URL, { replace: true });
  }
}