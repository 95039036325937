import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router';
import moment from 'moment';
import currencyFormatter from 'currency-formatter'
import { PreSignInHeader, DefaultFooter } from './common/HeaderAndFooter';
import { LiaCreditCard } from 'react-icons/lia';
import { PiReceipt } from 'react-icons/pi';
import Skeleton from "react-loading-skeleton";
import { ALLAY_TOKEN } from './common/Cookie';
import API from '../api';


export default function AccountTransactionDetailPage(props) {
  const { state } = useLocation();
  const [cookies] = useCookies([ALLAY_TOKEN]);

  const [transactionDetail, setTransactionDetail] = useState(null);

  useEffect(() => {
    API.getTransactionDetail(cookies[ALLAY_TOKEN], state.transId).then(response => {
      if (response.ok) {
        response.json().then(info => {
          setTransactionDetail(info.data);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container relative rounded-t-3xl bg-brand-primary-regular text-center content-center py-16">
          <div className="max-w-lg mx-auto space-y-8">
            <div className="text-left md:bg-brand-primary-light md:rounded-2xl md:w-full md:p-8">
              {transactionDetail
                ? <div className="space-y-6">
                  <h1 className="text-2xl text-content-regular pb-4">Payment #{state.transId}</h1>

                  <div className="space-y-2">
                    <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Payment detail</p>
                    <div className="flex py-2 border-b border-content-lighter">
                      <p className="flex-grow text-content-light">Subscription</p>
                      <p className="font-semibold">#{transactionDetail.subscription.id}</p>
                    </div>
                    <div className="flex py-2 border-b border-content-lighter">
                      <p className="flex-grow text-content-light">Amount</p>
                      <p className="font-semibold">${currencyFormatter.format(transactionDetail.authAmount, 'USD')}</p>
                    </div>
                    <div className="flex py-2 border-b border-content-lighter">
                      <p className="flex-grow text-content-light">Customer</p>
                      <div className="text-right">
                        <p>{transactionDetail.shipTo.firstName} {transactionDetail.shipTo.lastName}</p>
                        <p className="font-semibold text-sm">{transactionDetail.customer.email}</p>
                      </div>
                    </div>
                    <div className="flex py-2 border-b border-content-lighter">
                      <p className="flex-grow text-content-light">Date</p>
                      <p className="font-semibold">{moment(transactionDetail.submitTimeLocal).format("MMM DD, YYYY")}</p>
                    </div>
                    <div className="flex py-2 border-b border-content-lighter">
                      <p className="flex-grow text-content-light">Status</p>
                      <p className="font-semibold">{transactionDetail.responseReasonDescription}</p>
                    </div>
                  </div>

                  <div className="space-y-2 pt-6">
                    <p className="text-xs underline font-semibold pb-2 uppercase flex-grow">Billing information</p>
                    <p className="flex"><span className="pt-1 pr-2 text-content-light"><LiaCreditCard size="16" /></span><span className="text-content-light pr-1">{transactionDetail.payment.creditCard.cardType} ending in</span> <span className="font-semibold">{transactionDetail.payment.creditCard.cardNumber.replace(/X/g, '*')}</span></p>
                    <div className="flex">
                      <span className="pt-1 pr-2 text-content-light capitalize"><PiReceipt size="16" /></span>
                      <div>
                        <p className="text-content-light font-xs">Billed to</p>
                        <p className="font-semibold capitalize">{transactionDetail.billTo.firstName} {transactionDetail.billTo.lastName}</p>
                        <p>{transactionDetail.billTo.address}</p>
                        <p>{transactionDetail.billTo.city}, {transactionDetail.billTo.state} {transactionDetail.billTo.zip}</p>
                      </div>
                    </div>
                  </div>
                </div>
                : <div className="space-y-4">
                  <p><Skeleton /></p>
                  <p><Skeleton /></p>
                  <p><Skeleton count={2} /></p>
                  <p><Skeleton /></p>
                  <p><Skeleton count={2} /></p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  )
}