import React from 'react'
import { useNavigate } from "react-router-dom";
import { DefaultFooter, PreSignInHeader } from './common/HeaderAndFooter'
import { HOME_URL, PRIVACY_URL } from './common/Navigation';
import { GetRequestParam } from './common/Util';

export const COMPANY_NAME = "Griffin Labs";
export const COMPANY_ADDRESS_STREET = "6633 Flagstone Pl,";
export const COMPANY_ADDRESS_REGION = "Rancho Cucamonga, CA 91739";
export const COMPANY_EMAIL = "hello@getallay.com"


export default function TermsPage() {
  const is_mobile = GetRequestParam("device") === 'mobile';
  const mode = GetRequestParam("mode");

  if (is_mobile) return <MobileTermsPage mode={mode}/>;
  else return <WebTermsPage />;
}

function WebTermsPage() {
  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container relative rounded-t-3xl bg-brand-primary-regular py-16 md:py-20">
          <Terms />
        </div>
      </div>
      <DefaultFooter />
    </div>
  );
}

function MobileTermsPage(props) {
  return (
    <div className={`${props.mode === 'dark' ? 'bg-brand-primary-regularOnDark dark-mode' : 'bg-brand-primary-regular'}`}>
      <div className="app-container py-4">
        <Terms />
      </div>
    </div>
  );
}

function Terms() {
  let navigate = useNavigate();

  return (
    <div className="max-w-xl mx-auto">
      <h1 className="text-3xl pb-4">Terms of Use</h1>
      <div className="space-y-4">
        <p>Welcome to {COMPANY_NAME} Inc. (“{COMPANY_NAME},” “we,” “us,” or “our”) and our website at <span className="btn-inline" onClick={() => navigate(HOME_URL)}>getallay.com</span> (the “Website”). We're delighted you're here and are looking forward to providing you with our online services (the “Services”).</p>
        <p>We offer Visitors (as defined in our <span className="btn-inline" onClick={() => navigate(PRIVACY_URL)}>Privacy Policy</span>) access to the Website, and Customers (also defined in our Privacy Policy) access to the Services under the guidelines set out in these Terms of Use. By navigating the public areas or by accessing and utilizing the Services, you confirm that you've read, understood, and agreed to be legally bound by these Terms of Use and our Privacy Policy, collectively referred to as this “Agreement.” If you do not agree with any of these terms, please refrain from using our Website and/or Services. We may occasionally update these Terms of Use, and when we do, we will notify you via email (sent to the email address linked to your account) before the changes take effect.</p>
        <p>Any capitalized terms not explicitly defined in these Terms of Use are detailed in our Privacy Policy.</p>
        <p className="uppercase">IMPORTANT NOTICE: THE SECTIONS BELOW TITLED “BINDING ARBITRATION” AND “CLASS ACTION WAIVER” INCLUDE A BINDING ARBITRATION AGREEMENT AND CLASS ACTION WAIVER. THESE AFFECT YOUR LEGAL RIGHTS. PLEASE READ THEM CAREFULLY.</p>

        <p className="p-heading">1. NO MEDICAL ADVICE</p>
        <p>You acknowledge that {COMPANY_NAME} doesn't provide any form of medical care, medical opinion, medical advice, diagnosis, or treatment, and does not evaluate the necessity to seek medical attention, through the Website and the Services. The Website, Services, and any related content serve informational purposes only and are not intended to substitute professional medical advice, diagnosis, or treatment. Do not use the information on the Website to diagnose or treat health problems or diseases, or to prescribe any medication or other treatment. Always consult your physician or another qualified health provider before starting any medication or nutritional, herbal or homeopathic supplement, and with any questions you may have about a medical condition. Do not ignore professional medical advice or delay seeking it due to information you've read on the Website, the Services, and/or the Content. If you think you may have a medical emergency, call your doctor or 911 immediately. Your reliance on the Website, the Services, and the Content is strictly at your own risk. Information provided on the Website and any products or services purchased from our Website do not create a doctor-patient relationship between you and any of the health professionals affiliated with our Website. Information and statements about dietary supplements have not been evaluated by the Food and Drug Administration and are not meant to diagnose, treat, cure, or prevent any disease.</p>

        <p className="p-heading">2. DESCRIPTION AND USE OF SERVICES</p>
        <p>Our Services are designed to help you curate your own selection of supplements for daily use (“Allay Subscription Products”), which are delivered to you in neatly packed boxes on a subscription basis (“Subscription”).</p>
        <p>We provide both Visitors and Customers with access to our Website and Services as outlined below.</p>
        <p><span className="font-bold">Visitors.</span> As implied by the name, Visitors are individuals who browse the Website without registering. No login is necessary for Visitors. Visitors can: (i) view all publicly-accessible content on the Website; (ii) send us emails, and (iii) chat with us via our website.</p>
        <p><span className="font-bold">Customers.</span> All Customers must log in. In addition to what Visitors can do, Customers can access their personal account details, post Customer Content, and use our Services.</p>
        <p>{COMPANY_NAME} reserves the right to accept or reject any individual as a Customer at our sole discretion.</p>

        <p className="p-heading">3. USE OF PERSONAL INFORMATION</p>
        <p>The use of our Services may involve transmitting certain Personal Information to us. The collection and use of such Personal Information are governed by our <span className="btn-inline" onClick={() => navigate(PRIVACY_URL)}>Privacy Policy</span>, which is hereby incorporated in its entirety.</p>

        <p className="p-heading">4. PRODUCT DESCRIPTIONS AND AVAILABILITY</p>
        <p><span className="font-bold">(a) Product Descriptions.</span>  Our Website hosts descriptions of dietary supplements. We strive for accuracy in the descriptions of the supplements made available through our Services (collectively, the “Products”). However, we can't guarantee that product descriptions and other content are accurate, complete, reliable, current, or error-free. If a Product isn't as described, your sole remedy is to return it in unused condition. We reserve the right to modify, suspend, or discontinue any Product at any time without notice and without any liability.</p>
        <p><span className="font-bold">(b) Shipping.</span>   Packages are shipped via USPS Priority Mail and delivered within 5-7 business days after ordering. In case of shipping delays, expect to be contacted by a representative of the Allay team.</p>
        <p><span className="font-bold">(c) Refunds.</span>   You can get a refund for your first month of Allay products within 30 days of receipt, for any reason. If your first order includes multiple months' worth of products, only the first month's supply can be refunded under this policy.</p>
        <p><span className="font-bold">(d) Promotions.</span> Occasionally, we offer promotional or offer codes redeemable towards a purchase of Products on the Site, subject to expiration dates, minimum purchase amounts, product exclusions, and other conditions as determined by us in our sole discretion (“Promo Codes”).</p>
        <p>Promo Codes can only be used once per person. Only valid Promo Codes offered by us will be honored. You agree that Promo Codes: (i) must be used lawfully and for their intended purpose; (ii) can't be duplicated, sold, transferred, or made public without our express permission; (iii) may be disabled by us at any time for any reason without liability to us; (iv) must be used in accordance with their specific terms; (v) aren't redeemable for cash; and (vi) may expire before you use them. Promo Codes are void if copied, transferred, sold, exchanged or expired, or where prohibited.</p>

        <p className="p-heading">5. COMMUNITY GUIDELINES</p>
        <p>Just like any community, the {COMPANY_NAME} community functions best when its participants adhere to a few simple rules. By accessing and/or using the Platform, you hereby agree to comply with these community rules:</p>
        <ul className="space-y-4 pl-4 list-inside list-disc">
          <li className="content">You will comply with all applicable laws in your use of the Website and Services and will not use the Website and Services for any unlawful purpose.</li>
          <li className="content">You will not use the Website and Services to collect any market research for a competing business.</li>
          <li className="content">You will not upload, post, email, transmit, or otherwise make available any content that:
            <ul className="space-y-4 pt-4 pl-4 list-inside list-circle">
              <li className="content">Infringes any copyright, trademark, right of publicity, or other proprietary rights of any person or entity; or</li>
              <li className="content">Is threatening, defamatory, libelous, indecent, obscene, pornographic, invasive of another’s privacy, promotes violence, or</li>
              <li className="content">discloses any sensitive information about another person, including personal contact or credit card information.</li>
            </ul>
          </li>
          <li className="content">You will not “stalk” or otherwise harass another.</li>
          <li className="content">You will not impersonate any person or entity or falsely state or misrepresent your affiliation with a person or entity.</li>
          <li className="content">You will not decompile, reverse engineer, or disassemble any software or other products or processes accessible through the Website or Services.</li>
          <li className="content">You will not circumvent, alter, deactivate, degrade, or thwart any protections in the Website or Services.</li>
          <li className="content">You will not use automated means, like bots or data mining tools, to download or scrape data from the Website or Services, except for Internet search engines and non-commercial public archives that comply with our robots.txt file.</li>
          <li className="content">You will not enclose any {COMPANY_NAME}' trademark, logo, or other proprietary information without {COMPANY_NAME}' express written consent.</li>
          <li className="content">You will not use meta tags or any other "hidden text" using a {COMPANY_NAME}' name, trademark, or Product name without {COMPANY_NAME}' express written consent.</li>
          <li className="content">You will not overload our technical infrastructure.</li>
          <li className="content">You will not interfere with the operation of the Website and Services through any virus, device, information collection or transmission mechanism, software or routine, or access any data, files, or passwords related to the Website and Services through hacking, password or data mining, or any other means.</li>
          <li className="content">If you find something that violates our Community Guidelines, please let us know, and we’ll review it.</li>
        </ul>
        <p>We reserve the right to deny you access to the Website and Services, or any portion of the Website and Services, without notice, and to remove any comments that do not adhere to these guidelines.</p>

        <p className="p-heading">6. SIGN-IN NAME</p>
        <p>During the registration process for Customers, we will ask for your email address. You will also be asked to create an account, which includes a Sign-In Name (your email address). You must provide true, accurate, current, and complete information when creating your account. Each Sign-In Name can be used by only one Customer. You are responsible for the confidentiality and use of your Sign-In Name. You will promptly inform us of any need to deactivate a Sign-In Name. We reserve the right to delete or change your Sign-In Name at any time and for any reason.</p>

        <p className="p-heading">7. SUBSCRIPTION FEES, PAYMENTS, AND AUTOMATIC RENEWALS</p>
        <p>By subscribing to our service and ordering Allay Subscription Products, you are agreeing to our subscription model. This involves paying Subscription Fees which will be communicated to you at the point of subscription initiation. The amount you are required to pay corresponds to the specific product and service chosen.</p>
        <p>Payment is handled by a trusted third-party payment vendor to ensure a seamless and secure transaction. When entering your payment details, you are required to confirm that you are the rightful owner or an authorized user of the credit or debit card being used. It's crucial that all payment information you provide is accurate to prevent any issues or delays with your subscription service.</p>
        <p>The subscription model we use is based on automatic renewals. This means that unless you manually cancel your subscription, it will continue to renew automatically. The renewal process will result in the shipping of a new Allay product and the corresponding Subscription Fees being charged to your chosen payment method.</p>
        <p>It's important to note that the cancellation process requires a written notice from you. This notice must be received no later than five business days before the shipment of your next package. If we receive your cancellation notice within this timeframe, the cancellation will take effect before the next delivery cycle.</p>
        <p>We understand that circumstances can change, so we strive to provide a flexible service. However, if you wish to cancel your subscription, please plan ahead and notify us within the aforementioned timeframe to avoid any unwanted charges or shipments.</p>
        <p>Please be aware that your access to our services is conditional on the successful payment of the Subscription Fees. If for any reason a payment fails, or a charge is refunded, we reserve the right to immediately suspend or terminate your access and account, which also terminates this Agreement and all our obligations hereunder. </p>
        <p>We maintain the right to change any of the fees we charge, or to institute new or additional fees at any time, but we will provide you with a notice before any such changes take effect.</p>

        <p className="p-heading">8. SUSPENDING OR POSTPONING DELIVERY OF YOUR ALLAY SUBSCRIPTION PRODUCTS</p>
        <p>You may suspend or postpone the delivery of your Allay Subscription Products by following the instructions on the Website. However, you will not be able to suspend or postpone if we have already started preparing your next box of the Allay Subscription Products. You will be alerted by email when we start preparing your next box of Allay Subscription Products.</p>

        <p className="p-heading">9. INTELLECTUAL PROPERTY</p>
        <p>The Website and the Services contain materials, such as software, text, graphics, images, sound recordings, audiovisual works, and other material provided by or on behalf of {COMPANY_NAME} (collectively referred to as the “Content”). The Content may be owned by us or by third parties. The Content is protected under both United States and foreign laws. Unauthorized use of the Content may violate copyright, trademark, and other laws. You have no rights in or to the Content, and you will not use the Content except as permitted under this Agreement. No other use is permitted without prior written consent from us.</p>
        <p>If you violate any part of this Agreement, your permission to access and/or use the Content and the Services automatically terminates and you must immediately destroy any copies you have made of the Content.</p>
        <p>The trademarks, service marks, and logos of {COMPANY_NAME} (“{COMPANY_NAME} Trademarks”) used and displayed on the Website and the Services are registered and unregistered trademarks or service marks of {COMPANY_NAME}. Other company, product, and service names located on the Website and the Services may be trademarks or service marks owned by others (the “Third-Party Trademarks”, and, collectively with the {COMPANY_NAME} Trademarks, the “Trademarks”).</p>

        <p className="p-heading">10. CUSTOMER CONTENT</p>
        <p>Customers may post and/or create content through the Website and the Services, including but not limited to, reviews and comments about the Allay Subscription Products (collectively, the “Customer Content”). We cannot and do not review it all—we are merely acting as a passive conduit for distribution of the Customer Content to other users of the Website and the Services.</p>
        <p className="uppercase">YOU, AND NOT {COMPANY_NAME}, ARE ENTIRELY RESPONSIBLE FOR ALL YOUR CUSTOMER CONTENT THAT YOU UPLOAD, POST, E-MAIL, OR OTHERWISE TRANSMIT VIA THE WEBSITE AND THE SERVICES.</p>
        <p>You retain all copyrights and other intellectual property rights in and to the Customer Content. You do, however, hereby grant us a non-exclusive, royalty-free, sublicensable, transferable, perpetual license to modify, compile, combine with other content, copy, record, synchronize, transmit, translate, format, distribute, publicly display, publicly perform, and otherwise use or exploit your Customer Content as reasonably necessary to provide the Website and the Services.</p>

        <p className="p-heading">11. REPOSTING OF YOUR SOCIAL MEDIA CONTENT</p>
        <p>From time to time, {COMPANY_NAME}’s social media accounts (including Instagram, Twitter, Facebook, and Pinterest) repost a consumer’s photos, experiences, or stories from their personal social media account. {COMPANY_NAME} will never repost your personal social media content (“User Generated Content”) without first obtaining your express written permission.</p>
        <p>Further, by agreeing to allow {COMPANY_NAME} to use your UGC, you agree to grant {COMPANY_NAME} an irrevocable, perpetual, non-exclusive, fully paid-up, royalty-free, sublicensable license and right to reproduce, transmit, display, comment on, edit, modify, alter, exploit, create derivative works based upon, combine with other works (and all copyrights therein and thereto and all renewals and extensions thereof), and otherwise distribute the UGC and all elements of the UGC, including without limitation, the photo and the likeness of any persons embodied therein, in any and all media now known or hereafter devised, worldwide, in perpetuity without compensation, permission, notification, attribution, or restriction from you or any third party.</p>
        <p>You hereby release, discharge and agree to hold {COMPANY_NAME} and any person acting on {COMPANY_NAME}’s behalf harmless from all claims, demands, and liabilities whatsoever in connection with use of the UGC.</p>

        <p className="p-heading">12. CORRESPONDENCE WITH US</p>
        <p>We invite your communication via email but please avoid sharing confidential information in such communications. Any content you share in emails like feedback, queries, suggestions, etc. allows us to freely use the ideas and knowledge for any purpose we deem fit, including product and service development and marketing, incorporating the shared information.</p>

        <p className="p-heading">13. DISCLAIMER & LIABILITY</p>
        <p>The Website, Products, Content, Customer Content, and Services are all provided on an "As Is" and "As Available" basis with no warranties of any kind. We disclaim all warranties including Title, Merchantability, Non-infringement of third parties' rights, and Fitness for a particular purpose, along with any warranties that arise from dealing, performance, or trade usage.</p>
        <p>Any claim against us related to warranty, contract, or common law tort shall not make us liable for incidental or consequential damages, lost profits, or damages from lost data or business interruption due to Service use or inability to use the Services or the Content. Direct damages suffered due to Service use or the Content are limited to the amount you paid us for Service use in the immediate preceding three months.</p>
        <p>Some jurisdictions do not allow warranty exclusions, so some limitations may not apply to you. We are not liable for any typographical, technical, or pricing errors on the Website and Services, and we can change, correct, or improve the Website and Services anytime without notice.</p>
        <p>Any order can be canceled or modified if we suspect fraudulent or inappropriate activity or if the order contains a mistake or error.</p>
        <p>Always consult your doctor or medical advisor before starting any diet, exercise, or supplementation program. Read all product information provided by manufacturers and in product packaging and labels before use. The Website and Services do not create a doctor-patient relationship between you and our affiliated health professionals. Statements regarding dietary supplements have not been evaluated by the Food and Drug Administration and are not intended to diagnose, treat, cure or prevent any disease.</p>

        <p className="p-heading">14. LINKS TO EXTERNAL SITES</p>
        <p>Our Website and Services may have links to third-party websites or "External Sites". These are provided for your convenience and do not indicate our endorsement of the External Sites' content, which is not our responsibility. Contact the respective site administrators or webmasters if you have concerns about these links. Protect your computer from viruses when downloading from any website. Access these External Sites at your own risk.</p>

        <p className="p-heading">15. AGE RESTRICTION</p>
        <p>We cannot stop minors from accessing our Website due to the nature of the internet. However, all purchases and registrations on our Website must be made by adults who are at least 18 years old or by minors with parental consent, as per our Privacy Policy.</p>

        <p className="p-heading">16. INDEMNITY</p>
        <p>You agree to protect us and hold us harmless, including our officers, directors, useees, successors, licensees and assigns from any claims or demands, including legal and accounting fees, that arise from your Agreement breach, misuse of Content, Website or Services, or violation of any third-party rights, such as copyright, trademark, property, or privacy rights.</p>

        <p className="p-heading">17. COMPLIANCE WITH LAWS</p>
        <p>The Website and Services are based in the United States and we do not make any claims about the Content's appropriateness for use outside the United States. If you access the Website, Services, or Content from outside the United States, you do it at your own risk. You are responsible for complying with laws in your specific jurisdiction, whether inside or outside the United States.</p>

        <p className="p-heading">18. AGREEMENT TERMINATION</p>
        <p>We have the right to restrict, suspend or terminate this Agreement and your access to Services anytime, without prior notice or liability, at our sole discretion. We also reserve the right to change, suspend, or discontinue any part of the Services without prior notice or liability.</p>

        <p className="p-heading">19. DIGITAL MILLENNIUM COPYRIGHT ACT</p>
        <p>{COMPANY_NAME} respects the intellectual property rights of others and attempts to comply with all relevant laws. We will review all claims of copyright infringement received and remove any content deemed to have been posted or distributed in violation of any such laws.</p>
        <p>Our designated agent under the Digital Millennium Copyright Act (the “Act”) for the receipt of any Notification of Claimed Infringement which may be given under that Act is as follows:</p>
        <div>
          <p>{COMPANY_NAME} Inc.</p>
          <p>{COMPANY_ADDRESS_STREET}</p>
          <p>{COMPANY_ADDRESS_REGION}</p>
          <p className="btn-inline" onClick={() => window.location.href = `mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</p>
        </div>
        <p>If you believe that your work has been copied on the Services in a way that constitutes copyright infringement, please provide our agent with notice in accordance with the requirements of the Act, including (i) a description of the copyrighted work that has been infringed and the specific location on the Services where such work is located; (ii) a description of the location of the original or an authorized copy of the copyrighted work; (iii) your address, telephone number and email address; (iv) a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or the law; (v) a statement by you, made under penalty of perjury, that the information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf; and (vi) an electronic or physical signature of the owner of the copyright or the person authorized to act on behalf of the owner of the copyright interest.</p>

        <p className="p-heading">20. CONFIDENTIALITY</p>
        <p>You agree not to disclose {COMPANY_NAME} Confidential Information without {COMPANY_NAME}'s prior written consent. "{COMPANY_NAME} Confidential Information" includes: (a) all {COMPANY_NAME} software, technology and documentation relating to the Services; (b) customer and partner lists, and customer and partner information; (c) pricing information; and (d) the terms of this Agreement and any information regarding {COMPANY_NAME}'s operations, business plans and financial condition.</p>
        <p>{COMPANY_NAME} Confidential Information does not include information that: (a) is or becomes generally known to the public through no fault of or breach of this Agreement by you; (b) is rightfully known by you at the time of disclosure without an obligation of confidentiality; (c) is independently developed by you without use of {COMPANY_NAME}'s Confidential Information; or (d) you rightfully obtain from a third party without restriction on use or disclosure.</p>

        <p className="p-heading">21. BINDING ARBITRATION / CLASS WAIVER</p>
        <p className="uppercase">YOU AND WE EXPRESSLY AGREE THAT ANY LEGAL CLAIM, DISPUTE OR OTHER CONTROVERSY BETWEEN YOU AND US ARISING OUT OF OR OTHERWISE RELATING IN ANY WAY TO {COMPANY_NAME}, THE CONTENT OR THE SERVICE, OR ANY OTHER GOODS, SERVICES OR ADVERTISING BY {COMPANY_NAME}, INCLUDING CONTROVERSIES RELATING TO THE APPLICABILITY, ENFORCEABILITY OR VALIDITY OF ANY PROVISION OF THIS AGREEMENT (COLLECTIVELY “DISPUTES”), THAT IS NOT RESOLVED BY AN INFORMAL DISPUTE RESOLUTION CONFERENCE (AS DEFINED AND DESCRIBED BELOW), SHALL BE RESOLVED IN CONFIDENTIAL BINDING ARBITRATION CONDUCTED BEFORE ONE COMMERCIAL ARBITRATOR FROM THE AMERICAN ARBITRATION ASSOCIATION (“AAA”), RATHER THAN IN A COURT, AS DESCRIBED HEREIN. THE ARBITRATION WILL BE GOVERNED BY THE AAA'S CONSUMER ARBITRATION RULES AND, IF THE ARBITRATOR DEEMS THEM APPLICABLE, THE SUPPLEMENTARY PROCEDURES FOR CONSUMER RELATED DISPUTES (COLLECTIVELY “RULES AND PROCEDURES”). YOU ACKNOWLEDGE THAT YOU ARE VOLUNTARILY AND KNOWINGLY FORFEITING YOUR RIGHT TO A TRIAL BY JURY AND TO OTHERWISE PROCEED IN A LAWSUIT IN STATE OR FEDERAL COURT, EXCEPT AS EXPRESSLY PROVIDED HEREIN. FOR PURPOSES OF THIS ARBITRATION AGREEMENT, “DISPUTE” WILL ALSO INCLUDE DISPUTES THAT AROSE OR INVOLVE FACTS OCCURING BEFORE THE EXISTENCE OF THIS OR ANY PRIOR VERSIONS OF THE TERMS AND CONDITIONS AS WELL AS CLAIMS THAT MAY ARISE AFTER THE TERMINATION OF THESE TERMS AND CONDITIONS.</p>
        <p>In the event a Dispute arises between us, we are committed to working with you to reach a reasonable resolution. You and we agree that good faith informal efforts to resolve Disputes can result in a prompt, low‐cost and mutually beneficial outcome. You and we therefore agree that before either party commences arbitration against the other (or initiates an action in small claims court if a party so elects), we will personally meet and confer telephonically or via videoconference, in a good faith effort to resolve informally any Dispute covered by this Arbitration Agreement (“Informal Dispute Resolution Conference”). If you are represented by counsel, your counsel may participate in the conference, but you will also participate in the conference.</p>
        <p>The party initiating a Dispute must give notice to the other party in writing of its intent to initiate an Informal Dispute Resolution Conference (“Notice”), which shall occur within 45 days after the other party receives such Notice, unless an extension is mutually agreed upon by the parties. Notice to us that you intend to initiate an Informal Dispute Resolution Conference should be sent by email to <span className="btn-inline" onClick={() => window.location.href = `mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</span> or regular mail to our offices located at {COMPANY_ADDRESS_STREET} {COMPANY_ADDRESS_REGION}. The Notice must include: (1) your name, telephone number, mailing address, e‐mail address associated with your account (if you have one); (2) the name, telephone number, mailing address and e‐mail address of your counsel, if any; and (3) a detailed description of your legal claim.</p>
        <p>If we are unable to resolve a Dispute within 45 days of the Informal Dispute Resolution Conference, either party may initiate arbitration in accordance with the process described below.</p>
        <p className="uppercase">THE PARTIES ACKNOWLEDGE AND AGREE THAT, WITH LIMITED EXCEPTIONS, AND AS PROVIDED BELOW, ANY DISPUTE ON AN INDIVIDUAL BASIS IN ANY FORUM IS TO BE SUBJECT TO FINAL AND BINDING ARBITRATION. NOTWITHSTANDING THE FOREGOING, NOTHING IN THESE TERMS AND CONDITIONS PRECLUDES YOU FROM BRINGING AN ISSUE TO THE ATTENTION OF ANY FEDERAL, STATE, OR LOCAL GOVERNMENT AGENCIES THAT CAN, IF THE LAW ALLOWS, SEEK RELIEF FROM US ON YOUR BEHALF.</p>
        <p>Arbitration will be subject to the Federal Arbitration Act and not any state arbitration law. The arbitration will be conducted before one commercial arbitrator with substantial experience in resolving commercial contract disputes from the American Arbitration Association (“AAA”). As modified by these Terms and Conditions, and unless otherwise agreed upon by the parties in writing, the arbitration will be governed by the AAA’s Commercial Arbitration Rules and, if the arbitrator deems them applicable, the Supplementary Procedures for Consumer Related Disputes (collectively “Rules and Procedures”).</p>
        <p><span className="uppercase">YOU AND WE AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR OUR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</span> Further, unless both you and we agree otherwise, the arbitrator may not consolidate more than one person’s claims and may not otherwise preside over any form of a representative or class proceeding. The arbitrator may award any relief that a court could award that is individualized to the claimant and would not affect other Allay customers.</p>
        <p>If a court decides that applicable law precludes enforcement of any of this paragraph’s limitations as to a particular claim for relief, then that claim (and only that claim) must be severed from the arbitration and may be brought in court. All other claims will be arbitrated.</p>
        <p>Notwithstanding any provision in this Agreement to the contrary, we agree that if we make any future change to this arbitration provision, it will not apply to any individual claim(s) that you had already provided notice of to us.</p>
        <p>You and we agree that if for any reason a Dispute proceeds in court rather than in arbitration: (i) you and we waive any right to a jury trial; (ii) the Dispute will proceed solely on an individual, non-class, non-representative basis; and (iii) neither you nor we may be a class representative or class member or otherwise participate in any class, representative, consolidated, or private attorney general proceeding.</p>

        <p className="p-heading">22. GOVERNING LAW; VENUE; SEVERABILITY OF PROVISIONS</p>
        <p>Allay operates this Service from our offices located in California. Users who decide to access the Service from other locations do so voluntarily and are accountable for compliance with applicable local laws. Any access to the Service from jurisdictions where the contents of the Service are unlawful or penalized is forbidden.</p>
        <p>The legitimacy, interpretation, construction, and execution of this Agreement will be governed by the laws of the State of California, notwithstanding any conflict of law provisions.</p>
        <p>All components of this Agreement apply to the fullest extent permitted by law. Any failure on our part to enforce a provision of this Agreement does not constitute a waiver of such right. Both parties agree that, should any part of this Agreement be unenforceable as written, that part will be replaced with terms that most accurately reflect the intention of the unenforceable part, within the boundaries of the law. Except as otherwise stated in this Agreement, the unenforceability of a part of this Agreement does not impact the validity and enforceability of the remaining provisions. The section headings are solely for convenience and bear no legal significance.</p>

        <p className="p-heading">23. OTHER PROVISIONS</p>
        <p>This Agreement is guided by the internal substantive laws of the State of California, without considering its conflict of laws provisions. If this Agreement is terminated as per the Termination provision, the validity of the following Agreement provisions shall remain unaffected: “Subscription Fees, Payments and Automatic Renewals,” “Intellectual Property,” “Communications with Us,” “No Warranties; Limitation of Liability,” “Indemnification,” “Termination of the Agreement,” “Binding Arbitration,” “Class Action Waiver,” and “Miscellaneous.”</p>
        <p>Our failure to enforce any provision of the Agreement is not a waiver of that or any other provision. No waiver will be binding unless it's written. No such waiver will be construed as a waiver in any other or subsequent instance. Except as expressly agreed by us and you in writing, this Agreement represents the entire Agreement between you and us regarding the subject matter, superseding all prior or contemporaneous agreements, whether written or oral. The section headings are only for convenience and have no legal significance. This Agreement benefits our successors, assigns, licensees, and sublicensees.</p>

        <p className="p-heading">24. MISCELLANEOUS</p>
        <p>If any provision of this Agreement is found to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this Agreement and shall not affect the validity and enforceability of any remaining provisions. This is the entire Agreement between us relating to the subject matter herein and supersedes any and all prior or contemporaneous written or oral Agreements between us with respect to such subject matter. This Agreement may not be changed, waived or modified except by {COMPANY_NAME} as provided herein or otherwise by written instrument signed by {COMPANY_NAME}. This Agreement or any right, obligation, or remedy hereunder is not assignable, transferable, delegable, or sublicensable by you except with {COMPANY_NAME}’s prior written consent, and any attempted assignment, transfer, delegation, or sublicense shall be null and void. {COMPANY_NAME} may assign, transfer, or delegate this Agreement or any right or obligation or remedy hereunder in its sole discretion. No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. Any heading, caption or section title contained in this Agreement is inserted only as a matter of convenience and in no way defines or explains any section or provision hereof.</p>
        <p>This Agreement, together with all agreements and statements referred to herein and incorporated herein by reference, is the entire agreement between you and {COMPANY_NAME} relating to the subject matter hereof and, except as otherwise provided herein, supersedes any and all prior or contemporaneous written or oral agreements or understandings between you and {COMPANY_NAME} relating to such subject matter.</p>
      </div>
    </div>
  )
}