import React, { useState } from 'react'
import API from '../api';
import { PreSignInHeader, DefaultFooter } from './common/HeaderAndFooter';
import InputField from './common/InputField';

export default function PrivacyOptOutPage() {
  const [isOptedOut, setIsOptedOut] = useState(false);

  const DEFAULT_ERROR = "Please enter a valid first name, last name, and email address";
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  function handleOptOut() {
    // Check if the email address has the correct format
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(email) && firstName.length > 0 && lastName.length > 0) {
      setError('');
      API.submitPrivacyOptOut({
        "email": email,
        "first_name": firstName,
        "last_name": lastName
      }).then(response => {
        if (response.ok) {
          setIsOptedOut(true);
          setError('')
        }
      });
    } else {
      setError(DEFAULT_ERROR);
    }
  }

  return (
    <div className="bg-brand-tertiary-darker">
      <PreSignInHeader />
      <div className="app-container">
        <div className="section-container rounded-t-3xl bg-brand-primary-regular py-16 md:py-20">
          {isOptedOut ?
            <div className="flex items-center h-[50vh]">
              <div className="max-w-xl mx-auto space-y-4 text-center">
                <h1 className="text-4xl md:text-5xl text-content-regular pb-4">Opted out</h1>
                <p className="text-xl">You have opted out of all disclosures to third parties for direct marketing purposes and future sales of certain information that a we has or will collect</p>
              </div>
            </div>
            :
            <div className="max-w-xl mx-auto space-y-4 text-center">
              <h1 className="text-4xl md:text-5xl text-content-regular pb-4">Privacy Opt Out</h1>
              <p className="pb-8">Fill out the following Right to Opt Out form to proceed with your request.</p>
              <InputField
                className="flex-grow"
                label="First name"
                name="firstName"
                type="text"
                autocomplete="given-name"
                x_autocompletetype="given-name"
                onChange={(event) => setFirstName(event.target.value)} />
              <InputField
                className="flex-grow"
                label="Last name"
                name="lastName"
                type="text"
                autocomplete="family-name"
                x_autocompletetype="family-name"
                onChange={(event) => setLastName(event.target.value)} />
              <InputField
                label="Email address"
                name="email"
                type="email"
                autocomplete="email"
                x_autocompletetype="email"
                onChange={(event) => setEmail(event.target.value)} />
              <div className="py-8">
                {error.length > 0 && <p className="text-content-error">{error}</p>}
                <button className="btn-primary w-full" onClick={handleOptOut}>Opt out</button>
              </div>
            </div>
          }
        </div>
      </div>
      <DefaultFooter />
    </div >
  )
}